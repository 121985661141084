import styled from '@emotion/styled';
import React from 'react';

const StyledAvatar = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    img: {
        borderRadius: '100%',
        width: 64,
        height: 'auto',
        transition: 'width 300ms ease'
    }
});

export const DorisAvatar: React.FC = () => {
    return (
        <StyledAvatar className="doris-avatar">
            <img src="https://doris.diceplatform.com/player/static/media/doris.e50d226c.png" alt="Doris" />
        </StyledAvatar>
    );
};
