import { Box, Button } from '@mui/material';
import React from 'react';

import { ExportLogsTable, FileDownload } from '../components';
import { useRequestLogs } from '../components/modals/stores';

export const ExportLogs: React.FC = () => {
    const { openRequestLogsModal, exportLogsData, fetchExportLogsData } = useRequestLogs((state) => ({
        openRequestLogsModal: state.openOverlay,
        exportLogsData: state.exportLogsData,
        fetchExportLogsData: state.fetchExportLogsData
    }));

    React.useEffect(() => {
        fetchExportLogsData();
    }, [fetchExportLogsData]);

    return (
        <section>
            <Box display="flex" justifyContent="space-between" mb={3}>
                <h2>Export Logs</h2>
                <Box display="flex" alignItems="center">
                    <Button
                        onClick={openRequestLogsModal}
                        color="info"
                        variant="contained"
                        endIcon={<FileDownload fill="var(--font-color)" />}
                    >
                        Request Logs
                    </Button>
                </Box>
            </Box>
            <ExportLogsTable data={exportLogsData} />
        </section>
    );
};
