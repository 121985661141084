import { Auth } from '../Auth';
import { FetchAbortController } from '../FetchAbortController';

interface RequestConfig {
    /**
     * Request init options
     */
    options?: RequestInit;

    /**
     * Whether the auth token should be added to the request.
     *
     * @default true
     */
    withAuth?: boolean;
}

export class Http {
    public static get(url: string, config: RequestConfig = {}): Promise<Response> {
        return Http.createRequest('GET', url, config);
    }

    public static post(url: string, config: RequestConfig = {}): Promise<Response> {
        return Http.createRequest('POST', url, config);
    }

    public static put(url: string, config: RequestConfig = {}): Promise<Response> {
        return Http.createRequest('PUT', url, config);
    }

    private static createRequest(method: string, url: string, config: RequestConfig = {}): Promise<Response> {
        const requestConfig: RequestConfig = {
            ...config,
            options: {
                ...config.options,
                method
            }
        };

        return Http.fetch(url, requestConfig);
    }

    private static async fetch(url: string, config: RequestConfig = {}): Promise<Response> {
        const { options, withAuth = true } = config;
        const signal = options?.signal ?? new FetchAbortController().getSignal();
        const requestOptions: RequestInit = {
            ...options,
            signal
        };

        if (withAuth) {
            const { accessToken: authorization } = Auth.getAuthCredentials() ?? {};

            if (authorization) {
                requestOptions.headers = {
                    ...requestOptions.headers,
                    authorization: authorization
                };
            } else {
                Auth.clearSession();
                return new Response(null, { status: 401 });
            }
        }

        const response = await fetch(url, requestOptions);

        if (response.status === 401) {
            // TODO: Look into a way of refreshing access token
            Auth.clearSession();
        }

        return response;
    }
}
