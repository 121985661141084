/**
 * Safely parses the error of a try-catch block to a string.
 */
export const errorToString = (error: unknown): string => {
    if (error == null) {
        return '';
    } else if (error instanceof Error) {
        return `${error.name}: ${error.message}`;
    } else {
        return String(error);
    }
};
