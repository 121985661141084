import { Config } from './types';

export class FetchAbortController {
    private controller: AbortController;
    private signal: AbortSignal;
    private timeout: number;

    constructor(config: Config = {}) {
        const { timeoutDuration = 9000 } = config;

        this.controller = new AbortController();
        this.signal = this.controller.signal;
        this.timeout = window.setTimeout(() => {
            this.abort();
        }, timeoutDuration);
    }

    public getSignal(): AbortSignal {
        return this.signal;
    }

    private abort(): void {
        window.clearTimeout(this.timeout);
        this.controller.abort();
    }
}
