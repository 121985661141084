import styled from '@emotion/styled';
import React from 'react';

import { ComponentWithChildren } from '../types';

const StyledContainer = styled('div')({
    maxWidth: 1600,
    margin: '0 auto',
    padding: '0 24px 20px',
    height: '100%'
});

export const Container: React.FC<ComponentWithChildren> = ({ children }) => {
    return <StyledContainer>{children}</StyledContainer>;
};
