import autoBind from 'auto-bind';
import JwtDecode from 'jwt-decode';

import { LOCAL_STORAGE_KEY } from './constants';
import { AuthKeys } from './enums';
import { AuthCredentials, AwsCognitoUser } from './types';

export class Auth {
    private static authCredentials?: AuthCredentials;

    constructor() {
        autoBind(this);
    }

    public static getAuthCredentials(): AuthCredentials | undefined {
        return Auth.authCredentials;
    }

    public static isAuthenticated(): boolean {
        return Boolean(Auth.authCredentials);
    }

    public static getUserName(): string | undefined {
        if (Auth.authCredentials) {
            try {
                const user = JwtDecode<AwsCognitoUser>(Auth.authCredentials.idToken);
                return user['cognito:username'];
            } catch (error) {
                // InvalidTokenError
            }
        }
    }

    public static setAuthCredentials(): void {
        const hash = window.location.hash.substring(1);
        const params = hash.split('&');
        const authCredentials: Partial<AuthCredentials> = {};

        params.forEach((param) => {
            const [key, value] = param.split('=');
            const propName = this.getAuthPropertyName(key);
            if (propName) {
                authCredentials[propName] = value;
            }
        });

        if (Object.keys(authCredentials).length) {
            Auth.authCredentials = authCredentials as AuthCredentials;
            this.clearLocationHash();
            this.saveToLocalStorage();
        } else {
            this.readFromLocalStorage();
            this.handleExpiredSession();
        }
    }

    public static clearSession(): void {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        Auth.authCredentials = undefined;
        Auth.clearQueryString();
        window.location.reload();
    }

    // TODO: Look into a way of refreshing access token
    public static handleExpiredSession(): void {
        if (Auth.authCredentials) {
            const { accessToken } = Auth.authCredentials;
            const { exp } = JwtDecode<AwsCognitoUser>(accessToken);

            if (Date.now() >= exp * 1000) {
                this.clearSession();
            }
        }
    }

    private static clearQueryString(): void {
        const url = new URL(window.location.href);
        url.search = '';
        window.history.pushState({}, '', url);
    }

    private static saveToLocalStorage(): void {
        if (Auth.authCredentials) {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(Auth.authCredentials));
        }
    }

    private static readFromLocalStorage(): void {
        const value = localStorage.getItem(LOCAL_STORAGE_KEY);

        if (value) {
            Auth.authCredentials = JSON.parse(value);
        }
    }

    private static getAuthPropertyName(name: string): keyof AuthCredentials | undefined {
        switch (name) {
            case AuthKeys.ACCESS_TOKEN:
                return 'accessToken';
            case AuthKeys.EXPIRES_IN:
                return 'expiresIn';
            case AuthKeys.ID_TOKEN:
                return 'idToken';
            case AuthKeys.TOKEN_TYPE:
                return 'tokenType';
        }
    }

    private static clearLocationHash(): void {
        window.history.pushState('', document.title, window.location.pathname + window.location.search);
    }
}
