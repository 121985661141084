import { Dialog, DialogTitle } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { pruneInvalidValues } from '../../../utilities';
import { useRequestLogs } from '../stores';
import { ManualEventInput } from './ManualEventInput';
import { SearchByTournament } from './SearchByTournament';
import { ExportLogsDataFormSchema } from './types';

const minDateTime = moment(new Date(new Date().setFullYear(new Date().getFullYear() - 1))); // a year ago
const maxDateTime = moment(Date.now());

export const RequestLogs: React.FC = () => {
    const { closeOverlay, isOpen, initialValues, submit, isSearchByTournamentOpen } = useRequestLogs();

    const handleCloseOverlay = () => {
        closeOverlay();
        formik.resetForm();
    };

    const formik = useFormik<ExportLogsDataFormSchema>({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const payload = pruneInvalidValues(values);

            if (Object.keys(payload).length) {
                const response = submit(payload);
                handleCloseOverlay();
                toast.success('Export logs requested.');

                const result = await response;
                if (result) {
                    toast.error(result);
                }
            } else {
                handleCloseOverlay();
            }
        }
    });

    const setEventIds = (eventIds: string) => {
        formik.setFieldValue('eventIds', eventIds);
    };

    return (
        <Dialog open={isOpen} onClose={handleCloseOverlay} fullWidth maxWidth="sm">
            <DialogTitle>Request Logs</DialogTitle>
            {isSearchByTournamentOpen ? (
                <SearchByTournament setEventIds={setEventIds} />
            ) : (
                <ManualEventInput closeOverlay={handleCloseOverlay} formik={formik} maxDateTime={maxDateTime} minDateTime={minDateTime} />
            )}
        </Dialog>
    );
};

const dateValidator = Yup.string()
    .required()
    .test('Valid datetime', 'Must be a valid date/time', (value) => {
        return (value && moment(value).isValid()) || false;
    })
    .test('Within min datetime range', 'Must be within the last year', (value) => {
        return moment(value).isSameOrAfter(minDateTime);
    })
    .test('Within min datetime range', 'Must not be in the future', (value) => {
        return moment(value).isSameOrBefore(maxDateTime);
    });

const validationSchema: Yup.SchemaOf<ExportLogsDataFormSchema> = Yup.object().shape({
    eventIds: Yup.string()
        .required()
        .matches(/^(\s*\d+\s*,\s*)*\s*\d+\s*,?\s*$/, 'Must be a list of comma separated number(s)'),
    from: dateValidator,
    requestedBy: Yup.string().required().min(2),
    to: dateValidator
});
