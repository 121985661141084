import styled from '@emotion/styled';
import * as React from 'react';

export interface ErrorLabelProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

const StyledErrorLabel = styled('span')({
    display: 'block',
    color: 'var(--status-red)',
    fontSize: '0.8rem',
    marginBottom: 6
});

export const ErrorLabel: React.FC<ErrorLabelProps> = ({ children, style }: ErrorLabelProps) => {
    return <StyledErrorLabel style={style}>{children}</StyledErrorLabel>;
};
