import styled from '@emotion/styled';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { AlertTypes } from '../enums';
import { getStatusColor } from '../styles';
import { CheckCircle, ErrorCircle } from './icons';
import { LastUpdateTime } from './LastUpdateTime';

export interface TopBarProps {
    href: string;
    status?: AlertTypes;
    tournamentName?: string;
}

type StyledProps = Omit<TopBarProps, 'href' | 'setIsLegendActive'>;

const SVG_HEIGHT = 30;

const StyledHeader = styled('header')({
    backgroundColor: 'var(--primary)',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 0 30px',
    minHeight: 72,
    color: 'var(--font-color)',
    h1: {
        margin: 0,
        display: 'inline-flex',
        alignItems: 'center',
        marginRight: '1rem',
        fontSize: '32px'
    },
    '.flex': {
        display: 'flex'
    }
});

const StyledHeaderContent = styled('div')((props: StyledProps) => {
    return {
        backgroundColor: getStatusColor(props.status),
        position: 'relative',
        borderTopRightRadius: '40px',
        borderBottomRightRadius: '40px',
        marginRight: '1.5rem',
        a: {
            display: 'inline-flex',
            padding: '12px 18px',
            color: 'var(--font-color)',
            textDecoration: 'none',
            height: '100%'
        },
        span: {
            display: 'flex',
            alignItems: 'center',
            svg: {
                fill: 'var(--font-color)'
            }
        }
    };
});

const TopBarIcon: React.FC<StyledProps> = ({ status }: StyledProps) => {
    switch (status) {
        case AlertTypes.YELLOW:
            return (
                <span>
                    <ErrorCircle height={SVG_HEIGHT} width={SVG_HEIGHT} />
                </span>
            );
        case AlertTypes.RED:
            return (
                <span>
                    <ErrorCircle height={SVG_HEIGHT} width={SVG_HEIGHT} />
                </span>
            );
        default:
            return (
                <span>
                    <CheckCircle height={SVG_HEIGHT} width={SVG_HEIGHT} />
                </span>
            );
    }
};

export const TopBar: React.FC<TopBarProps> = ({ status, tournamentName, href }: TopBarProps) => {
    return (
        <StyledHeader>
            <div className="flex">
                <StyledHeaderContent status={status}>
                    <Link to={href}>
                        <h1>Platform</h1>
                        <TopBarIcon status={status} />
                    </Link>
                </StyledHeaderContent>
                {tournamentName && <h2>{tournamentName}</h2>}
            </div>
            <div className="flex">
                <LastUpdateTime />
            </div>
        </StyledHeader>
    );
};
