import styled from '@emotion/styled';
import React from 'react';
import { useParams } from 'react-router-dom';

import { TournamentCards, TournamentHeading } from '../components';
import { useValidationConfig } from '../components/modals/stores';
import { ValidationType } from '../enums';
import { useAppContext } from '../hooks';
import { dashBoardService } from '../services';

const StyledTournaments = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 30,
    '.tournament__card': {
        margin: '0 16px 30px 0'
    },
    '.tournaments-card-wrapper': {
        marginBottom: 30
    },
    '.tournaments--ok .tournament__heading': {
        borderRadius: 3,
        boxShadow: '0 1px 2px var(--tertiary)',
        width: 330,
        transition: 'background-color 0.3s ease, transform 0.3s ease',
        margin: '0 16px 30px 0'
    }
});

export const Tournament: React.FC = () => {
    const { id = 0 } = useParams<{ id: string }>();
    const { dgeEvents, tournamentCards } = useAppContext();
    const { openValidationConfigModal } = useValidationConfig((state) => ({
        openValidationConfigModal: state.openOverlay
    }));

    const allEventCards = dashBoardService.getEventCards(+id, tournamentCards, dgeEvents);
    const events = dashBoardService.getEventsInTournamentWithoutErrors(+id, allEventCards, dgeEvents);
    const unhealthyEvents = allEventCards.filter((eventCard) => eventCard.eventIssues.some((eventIssue) => eventIssue.issues.length !== 0));

    const onEditClick = (id: number) => {
        openValidationConfigModal({ eventOrTournament: ValidationType.EVENT, eventOrTournamentId: String(id) });
    };

    return (
        <>
            {!!unhealthyEvents.length && (
                <StyledTournaments>
                    <TournamentCards tournamentCards={unhealthyEvents} onEditClick={onEditClick} />
                </StyledTournaments>
            )}
            {!!events.length && (
                <StyledTournaments>
                    <div className="tournaments--ok">
                        {events.map(({ title, id, dgeUrl }) => (
                            <TournamentHeading key={id} name={title} onEditClick={() => onEditClick(id)} href={dgeUrl} />
                        ))}
                    </div>
                </StyledTournaments>
            )}
        </>
    );
};
