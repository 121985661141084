import { Dialog, DialogTitle } from '@mui/material';
import * as React from 'react';

import { useValidationConfig } from '../stores';
import { SearchForm } from './SearchForm';
import { UpdateForm } from './UpdateForm';

export const ValidationConfigModal: React.FC = () => {
    const { isOpen, closeOverlay, isUpdateFormOpen } = useValidationConfig();

    return (
        <Dialog open={isOpen} onClose={closeOverlay}>
            <DialogTitle>Validation Configuration</DialogTitle>
            {isUpdateFormOpen ? <UpdateForm /> : <SearchForm />}
        </Dialog>
    );
};
