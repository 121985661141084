import * as React from 'react';
import { FallbackProps, ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { ComponentWithChildren } from '../types';

const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
    return (
        <div style={{ textAlign: 'center' }}>
            <h1>Oops!</h1>
            <h2>Something went wrong</h2>
            <h3>Please expand the details section below and share it with the Doris team</h3>
            <details style={{ whiteSpace: 'pre-wrap' }}>
                {error.toString()}
                <br />
                {error.stack}
            </details>
        </div>
    );
};

export const ErrorBoundary: React.FC<ComponentWithChildren> = ({ children }) => {
    const onError = (error: Error, info: { componentStack: string }) => {
        console.error(error.message, error, info);
    };

    return (
        <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
            {children}
        </ReactErrorBoundary>
    );
};
