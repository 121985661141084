export enum Alerts {
    AudioTracksLength = 'AudioTracksLength',
    AudioSegmentPlaylistDuration = 'AudioSegmentPlaylistDuration',
    CurrentDvrWindowLength = 'CurrentDvrWindowLength',
    DiscontinuityMisalignment = 'DiscontinuityMisalignment',
    FragDecoded = 'FragDecoded',
    HasCaptions = 'HasCaptions',
    HttpSegmentStatusCode = 'HttpSegmentStatusCode',
    HttpStatusCode = 'HttpStatusCode',
    HttpStatusCodeDGE = 'HttpStatusCodeDGE',
    HttpStatusCodeDGENoResponse = 'HttpStatusCodeDGENoResponse',
    HttpStatusCodeNoResponse = 'HttpStatusCodeNoResponse',
    InvalidContainerFormat = 'InvalidContainerFormat',
    LinearChannelNotLive = 'LinearChannelNotLive',
    NegativeSegmentDuration = 'NegativeSegmentDuration',
    PlaylistNotUpdating = 'PlaylistNotUpdating',
    SegmentBitrate = 'SegmentBitrate',
    SegmentDimensionsIsValid = 'SegmentDimensionsIsValid',
    SegmentDuration = 'SegmentDuration',
    SegmentFrameRate = 'SegmentFrameRate',
    SegmentKeyFrameAligned = 'SegmentKeyFrameAligned',
    SegmentKeyFrameInterval = 'SegmentKeyFrameInterval',
    SegmentsNotFound = 'SegmentsNotFound',
    TargetDuration = 'TargetDuration',
    UnableToFetchMasterPlaylist = 'UnableToFetchMasterPlaylist',
    UnableToFetchMediaPlaylists = 'UnableToFetchMediaPlaylists',
    VariantsLength = 'VariantsLength',
    VideoSegmentPlaylistDuration = 'VideoSegmentPlaylistDuration'
}
