import React from 'react';

import { FilterDashboard, RequestLogs, ValidationConfigModal } from '../components/modals';

export const ModalRoot: React.FC = () => {
    return (
        <React.Fragment>
            <FilterDashboard />
            <RequestLogs />
            <ValidationConfigModal />
        </React.Fragment>
    );
};
