import * as React from 'react';

import { Auth } from '../../services';
import { ComponentWithChildren } from '../../types';
import { INITIAL_STATE } from './constants';
import { AuthContextType } from './types';

export const AuthContext = React.createContext<AuthContextType>(INITIAL_STATE);

export const AuthContextProvider: React.FC<ComponentWithChildren> = (props) => {
    const [isAuthenticated, setIsAuthenticated] = React.useState(INITIAL_STATE.isAuthenticated);

    React.useEffect(() => {
        Auth.setAuthCredentials();
        setIsAuthenticated(Auth.isAuthenticated());
    }, []);

    const value: AuthContextType = {
        ...INITIAL_STATE,
        isAuthenticated
    };

    return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};
