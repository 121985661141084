import * as React from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { AuthContextType } from '../contexts/AuthContext/types';

export function useAuthContext(): AuthContextType {
    const context = React.useContext(AuthContext);

    if (!context) {
        throw new Error('AuthContext must be used inside its Provider');
    }

    return context;
}
