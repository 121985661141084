import styled from '@emotion/styled';
import React from 'react';

import { DorisAvatar } from '../components';
import { API_ROUTES } from '../constants';

const StyledContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
    a: {
        minHeight: 40,
        padding: 6,
        textAlign: 'center',
        backgroundColor: 'var(--light)',
        color: 'var(--tertiary)',
        minWidth: 200,
        fontSize: '1.3rem',
        textDecoration: 'none',
        borderRadius: 3,
        transition: 'color 0.25s ease, background-color 0.25s ease, transform 0.25s ease',
        '&:hover': {
            color: 'var(--font-color)',
            backgroundColor: 'var(--doris-primary)',
            transform: 'scale(1.1)'
        }
    },
    '.doris-avatar img': {
        marginBottom: '2rem',
        width: '100%'
    }
});

export const Login: React.FC = () => {
    return (
        <StyledContainer>
            <section>
                <h1>Doris Monitoring</h1>
                <DorisAvatar />
            </section>
            <a href={API_ROUTES.LOGIN}>Login</a>
        </StyledContainer>
    );
};
