type Payload = Record<string, unknown>;

const invalidValues = [undefined, null, ''];

export const pruneInvalidValues = (object: Payload): Payload => {
    const data: Payload = {};
    Object.keys(object).forEach((key) => {
        if (!invalidValues.includes(object[key] as any)) {
            data[key] = object[key];
        }
    });
    return data;
};
