import { AlertTypes } from '../enums/AlertTypes';

export const getStatusColor = (status?: AlertTypes): string => {
    switch (status) {
        case AlertTypes.RED:
            return 'var(--status-red)';
        case AlertTypes.YELLOW:
            return 'var(--status-yellow)';
        default:
            return 'var(--status-green)';
    }
};
