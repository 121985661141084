import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Alert, Drawer, Legend, NavBar } from '../components';
import { Routes as AppRoutes } from '../enums/Routes';
import { useDrawer } from '../stores';
import { Container } from './Container';
import { ExportLogs } from './ExportLogs';
import { Login } from './Login';
import { ModalRoot } from './ModalRoot';
import { ProtectedRoute } from './ProtectedRoute';
import { Toast } from './Toast';
import { TopBarContainer } from './TopBar';
import { Tournament } from './Tournament';
import { Tournaments } from './Tournaments';
import { UnprotectedRoute } from './UnprotectedRoute';

export const Root: React.FC = () => {
    const { closeOverlay, isOpen, openOverlay } = useDrawer();

    return (
        <React.Fragment>
            <ProtectedRoute>
                <NavBar onMenuClick={openOverlay} />
                <TopBarContainer />
            </ProtectedRoute>
            <Container>
                <Drawer isOpen={isOpen} onClose={closeOverlay} />
                <ModalRoot />
                <ProtectedRoute>
                    <Alert />
                    <Routes>
                        <Route path={AppRoutes.EXPORT_LOGS} element={<ExportLogs />} />
                        <Route path={`${AppRoutes.TOURNAMENTS}/:id`} element={<Tournament />} />
                        <Route path="/" element={<Tournaments />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                    <Legend />
                </ProtectedRoute>
                <UnprotectedRoute>
                    <Login />
                </UnprotectedRoute>
            </Container>
            <Toast />
        </React.Fragment>
    );
};
