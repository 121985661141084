import moment from 'moment';

import { EventIssueEntry, TournamentCardProps, TournamentEventIssue } from '../components/TournamentCard';
import { AlertTypes } from '../enums';
import { Event, EventState, MonitoringState, Tournament, TournamentLinkRoute } from '../types';
import { errorClassifier } from './errorClassifier';

class DashBoard {
    public getDgeLinkFromEvent(event: Event) {
        const {
            id: eventId,
            tournament: {
                id: tournamentId,
                property: {
                    id: propertyId,
                    sport: { id: sportId }
                }
            }
        } = event;

        return `https://live.diceplatform.com/content/sports/${sportId}/properties/${propertyId}/tournaments/${tournamentId}/events/${eventId}`;
    }

    /**
     * Compares DGE events with the Monitoring Service Cluster
     * @returns Events that are live in DGE but not in the cluster
     */
    public getUnmonitoredEvents(dgeEvents: Event[], monitoringState?: MonitoringState): Event[] {
        if (!monitoringState) {
            return [];
        }

        const monitoredEvents = this.getEventIdsFromState(monitoringState);

        return dgeEvents.reduce((events, event) => {
            const hasEvent = monitoredEvents.find((monitoredEvent) => monitoredEvent === event.id);

            if (!hasEvent) {
                events.push(event);
            }

            return events;
        }, [] as Event[]);
    }

    public getEventsByTournamentId(tournamentId: number, events: Event[]): Event[] {
        return events.filter((event) => event.tournament.id === tournamentId);
    }

    public getTournamentFromEvents(tournamentId: number, events: Event[]): Tournament | undefined {
        return events.find((event) => event.tournament.id === tournamentId)?.tournament;
    }

    public getAllTournaments(dgeEvents: Event[]): Tournament[] {
        const tournaments = new Map<Tournament['id'], Tournament>();
        dgeEvents.forEach(({ tournament }) => {
            tournaments.set(tournament.id, tournament);
        });

        return Array.from(tournaments.values()).sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    public getEventsInTournamentWithoutErrors(tournamentId: number, eventCards: TournamentCardProps[], dgeEvents: Event[]): Event[] {
        return dgeEvents
            .reduce((events, event) => {
                if (event.tournament.id === tournamentId) {
                    const eventInEventCards = eventCards.some(
                        (eventCard) =>
                            eventCard.title === event.title && eventCard.eventIssues.some((eventIssue) => eventIssue.issues.length > 0)
                    );

                    if (!eventInEventCards) {
                        events.push(event);
                    }
                }

                return events;
            }, [] as Event[])
            .sort((a, b) => (a.title > b.title ? 1 : -1));
    }

    public getPlatformStatus(tournamentCards: TournamentCardProps[]): AlertTypes {
        return tournamentCards.reduce((alertType, cards) => {
            const nextAlertType = cards.eventStatus;

            if (nextAlertType === AlertTypes.RED) {
                alertType = nextAlertType;
            } else if (nextAlertType === AlertTypes.YELLOW && alertType !== AlertTypes.RED) {
                alertType = nextAlertType;
            }

            return alertType;
        }, AlertTypes.GREEN as AlertTypes);
    }

    public getEventCards(tournamentId: number, tournamentCards: TournamentCardProps[], dgeEvents: Event[]): TournamentCardProps[] {
        const dgeEvent = dgeEvents.find((event) => event.tournament.id === tournamentId);
        const tournament = tournamentCards.find((tournamentCard) => tournamentCard.tournamentId === dgeEvent?.tournament.id);
        const eventCardProps: TournamentCardProps[] = [];

        if (tournament) {
            tournament.eventIssues.forEach(({ eventId, eventName, issues }) => {
                const event = dgeEvents.find((event) => event.id === eventId);
                const eventIssues: TournamentEventIssue[] = [
                    {
                        eventId,
                        eventName,
                        withoutEventName: true,
                        issues
                    }
                ];

                eventCardProps.push({
                    title: eventName,
                    eventIssues,
                    eventStatus: tournament.eventStatus,
                    tournamentId: tournament.tournamentId,
                    eventId,
                    dgeUrl: event ? this.getDgeLinkFromEvent(event) : undefined
                });
            });
        }

        return eventCardProps.sort((a, b) => (a.title > b.title ? 1 : -1));
    }

    public getTournamentCards(dgeEvents: Event[], monitoringState?: MonitoringState): TournamentCardProps[] {
        // TODO: If we have dgeEvents and no monitoringState, return all events as not being monitored?
        if (!monitoringState) {
            return [];
        }

        const tournamentCardProps: TournamentCardProps[] = [];
        const tournaments = this.getAllTournaments(dgeEvents);
        const unmonitoredEvents = this.getUnmonitoredEvents(dgeEvents, monitoringState);

        tournaments.forEach((tournament) => {
            const eventsInTournament = this.getEventsByTournamentId(tournament.id, dgeEvents);
            const unmonitoredEventsInTournament = this.getEventsByTournamentId(tournament.id, unmonitoredEvents);
            const eventIssues: TournamentEventIssue[] = [];
            let eventsWithIssuesCount = 0;

            // Add events that are not currently being monitored
            if (unmonitoredEventsInTournament.length) {
                const unmonitoredEventIssues: TournamentEventIssue[] = unmonitoredEventsInTournament.map((event) => ({
                    eventId: event.id,
                    eventName: event.title,
                    issues: [{ message: 'Event not monitored' }],
                    eventImageUrl: event.poster?.replace('original', '16x16')
                }));

                eventIssues.push(...unmonitoredEventIssues);

                // increment issues we have found so far
                eventsWithIssuesCount += unmonitoredEventIssues.length;
            }

            eventsInTournament.forEach((event) => {
                const eventIssue = this.getIssuesFromEvent(event, monitoringState);

                if (eventIssue?.issues.length) {
                    // Monitored events with issues
                    eventIssues.push(eventIssue);
                    eventsWithIssuesCount += 1;
                } else {
                    // Healthy monitored events
                    eventIssues.push({
                        eventId: event.id,
                        eventName: event.title,
                        issues: [],
                        eventImageUrl: event.poster?.replace('original', '16x16')
                    });
                }
            });

            const tournamentIssues = this.getLogErrorsFromTournament(tournament.id, monitoringState);
            const hasUnMonitoredEvent = unmonitoredEventsInTournament.length > 0;
            let eventStatus = errorClassifier.getAlertTypeFromEventIssues(tournamentIssues);

            if (eventStatus === AlertTypes.GREEN && hasUnMonitoredEvent) {
                eventStatus = AlertTypes.YELLOW;
            }

            const count =
                eventsWithIssuesCount === 0 ? `${eventsInTournament.length}` : `${eventsWithIssuesCount}/${eventsInTournament.length}`;

            tournamentCardProps.push({
                count,
                eventIssues,
                eventStatus,
                title: tournament.name,
                tournamentId: tournament.id,
                href: `tournaments/${tournament.id}`
            });
        });

        return tournamentCardProps;
    }

    /**
     * @param monitoringState The events being monitored in the cluster
     * @returns The tournament links for events that are being monitored and have no errors
     */
    public getTournamentLinks(dgeEvents: Event[], monitoringState?: MonitoringState): TournamentLinkRoute[] {
        return this.getTournamentCards(dgeEvents, monitoringState)
            .filter((tournamentCard) => tournamentCard.eventIssues.every((eventIssue) => eventIssue.issues.length === 0)) // Get tournamentCards without issues
            .map<TournamentLinkRoute>((tournamentCard) => ({
                count: tournamentCard.eventIssues.length,
                id: tournamentCard.tournamentId,
                name: tournamentCard.title
            }));
    }

    private getIssuesFromEvent(event: Event, monitoringState: MonitoringState): TournamentEventIssue | undefined {
        const eventId = event.id;
        const eventState = monitoringState.events[eventId];

        if (!eventState) {
            return undefined;
        }

        const issues = eventState.issues.map<EventIssueEntry>((issue) => {
            const from = moment(issue.timestamps[0]).format('LT');
            const to = moment(issue.timestamps[issue.timestamps.length - 1]).format('LT');
            let timestamp = from;

            if (issue.timestamps.length > 1 && from !== to) {
                timestamp += ` - ${to}`;
            }

            return {
                message: issue.message,
                count: issue.timestamps.length,
                timestamp
            };
        });

        return {
            eventId,
            eventName: eventState.title,
            issues,
            eventImageUrl: event.poster?.replace('original', '16x16')
        };
    }

    private getLogErrorsFromTournament(tournamentId: number, monitoringState: MonitoringState) {
        return this.getEventsInTournament(tournamentId, monitoringState).flatMap((event) => event.issues);
    }

    private getEventsInTournament(tournamentId: number, monitoringState: MonitoringState): EventState[] {
        return this.getEventIdsFromState(monitoringState).reduce((events, eventId) => {
            const event = monitoringState.events[eventId];
            if (event.tournament.id === tournamentId) {
                events.push(event);
            }
            return events;
        }, [] as EventState[]);
    }

    private getEventIdsFromState(monitoringState: MonitoringState): number[] {
        return Object.keys(monitoringState.events).map((eventId) => parseInt(eventId));
    }
}

export const dashBoardService = new DashBoard();
