import * as React from 'react';

import { AppContextType } from '../contexts/AppContext/types';
import { AppContext } from '../contexts/AppContext/context';

export function useAppContext(): AppContextType {
    const context = React.useContext(AppContext);

    if (!context) {
        throw new Error('AppContext must be used inside its Provider');
    }

    return context;
}
