import styled from '@emotion/styled';
import moment from 'moment';
import React from 'react';

import { useAppContext } from '../hooks';

const StyledLastUpdateTIme = styled('div')({
    display: 'flex',
    alignItems: 'center',
    paddingRight: 16
});

export const LastUpdateTime: React.FC = () => {
    const { lastUpdateTime } = useAppContext();
    const [fromNow, setFromNow] = React.useState(moment(lastUpdateTime).fromNow());
    const updateRef = React.useRef<number>();

    // This is used when the API is down as the State isn't updated
    React.useEffect(() => {
        const updateFromNow = () => {
            updateRef.current = window.setTimeout(() => {
                const time = moment(lastUpdateTime).format('LTS');
                const fromNow = moment(lastUpdateTime).fromNow();
                setFromNow(`${fromNow} at ${time}`);
                updateFromNow();
            }, 1000);
        };

        if (lastUpdateTime) {
            updateFromNow();
        }

        return () => window.clearTimeout(updateRef.current);
    }, [lastUpdateTime]);

    if (!lastUpdateTime) {
        return null;
    }

    return (
        <StyledLastUpdateTIme>
            <span>Last Updated: {fromNow}</span>
        </StyledLastUpdateTIme>
    );
};
