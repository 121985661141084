import moment from 'moment';
import create from 'zustand';
import { Monitoring } from '../../../services';

import { BaseOverlayProps, ExportLogsData, ExportLogsDataRequest } from '../../../types';
import { ExportLogsDataFormSchema } from '../RequestLogs/types';

interface State extends Pick<BaseOverlayProps, 'isOpen'> {
    exportLogsData: ExportLogsData[];
    initialValues: ExportLogsDataFormSchema;
    isSearchByTournamentOpen: boolean;
}

interface Actions extends Omit<BaseOverlayProps, 'isOpen'> {
    fetchExportLogsData: () => Promise<void>;
    submit: (payload: any) => Promise<string | undefined>;
    setIsSearchByTournamentOpen: (isSearchByTournamentOpen: boolean) => void;
}

const INITIAL_STATE: State = {
    exportLogsData: [],
    isOpen: false,
    isSearchByTournamentOpen: false,
    initialValues: {
        eventIds: '',
        from: '',
        requestedBy: '',
        to: ''
    }
};

export const useRequestLogs = create<State & Actions>((set, get) => ({
    ...INITIAL_STATE,
    closeOverlay: () => {
        const { exportLogsData } = get();
        set({ ...INITIAL_STATE, exportLogsData });
    },
    fetchExportLogsData: async () => {
        const exportLogsData = await Monitoring.getExportLogsData();
        set({ exportLogsData });
    },
    openOverlay: () => {
        set({ isOpen: true });
    },
    submit: async (payload: ExportLogsDataFormSchema) => {
        const { exportLogsData } = get();
        const data: ExportLogsDataRequest = {
            ...payload,
            eventIds: payload.eventIds
                .split(',')
                .map((id) => parseInt(id, 10))
                .filter((id) => !isNaN(id))
        };

        data.to = moment(data.to).toISOString();
        data.from = moment(data.from).toISOString();

        const exportLogData = await Monitoring.createExportLogsData(data);

        if (typeof exportLogData === 'string') {
            return exportLogData;
        }

        set({ exportLogsData: [exportLogData, ...exportLogsData] });
    },
    setIsSearchByTournamentOpen: (isSearchByTournamentOpen: boolean) => set({ isSearchByTournamentOpen })
}));
