import styled from '@emotion/styled';
import { Box, Button, DialogActions, DialogContent, DialogContentText, List, ListItemButton, ListItemText, TextField } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';

import { useAppContext, useSearchTournament } from '../../../hooks';
import { useRequestLogs } from '../stores';

interface SearchByTournamentProps {
    setEventIds: (eventIds: string) => void;
}

export const SearchByTournament: React.FC<SearchByTournamentProps> = ({ setEventIds }) => {
    const { dgeEvents } = useAppContext();
    const { setIsSearchByTournamentOpen } = useRequestLogs();
    const [searchValue, setSearchValue] = React.useState('');
    const tournaments = useSearchTournament(dgeEvents, searchValue);

    const onTournamentSelected = (tournamentId: number) => {
        const events = dgeEvents
            .filter((event) => event.tournament.id === tournamentId)
            .map((event) => event.id)
            .join(',');

        setEventIds(events);
        setIsSearchByTournamentOpen(false);
    };

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, translateX: -10 }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.8, type: 'spring' }}
            >
                <DialogContent style={{ maxHeight: 600 }}>
                    <Box mb={2}>
                        <DialogContentText textAlign="center">Search events by Tournament</DialogContentText>
                        <TextField
                            id="search"
                            label="Search"
                            value={searchValue}
                            onChange={(event) => setSearchValue(event.target.value)}
                            variant="standard"
                            fullWidth
                        />
                        <List>
                            {tournaments.map(([id, label]) => (
                                <StyledListItemButton key={id} onClick={() => onTournamentSelected(id)}>
                                    <ListItemText>{label}</ListItemText>
                                    <RightListItemText>{id}</RightListItemText>
                                </StyledListItemButton>
                            ))}
                        </List>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box>
                        <Button color="error" onClick={() => setIsSearchByTournamentOpen(false)}>
                            Cancel
                        </Button>
                    </Box>
                </DialogActions>
            </motion.div>
        </AnimatePresence>
    );
};

const StyledListItemButton = styled(ListItemButton)({
    display: 'flex',
    justifyContent: 'space-between'
});

const RightListItemText = styled(ListItemText)({
    textAlign: 'right'
});
