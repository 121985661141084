import styled from '@emotion/styled';
import * as React from 'react';

import { ERROR_DESCRIPTIONS } from '../constants';
import { useAppContext, useBodyScrollLock } from '../hooks';
import { Close } from './icons';

interface StyledLegendProps {
    isLegendActive: boolean;
}

const StyledLegend = styled('div')((props: StyledLegendProps) => {
    return {
        backgroundColor: 'var(--light)',
        color: 'var(--tertiary)',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        transition: 'opacity 0.3s ease',
        zIndex: 1,
        opacity: props.isLegendActive ? 1 : 0,
        pointerEvents: props.isLegendActive ? 'all' : 'none',
        height: '100%',
        width: '100%',
        overflow: 'scroll',
        section: {
            padding: '0 10px'
        },
        '.boxes': {
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap'
        },
        '.box': {
            height: 100,
            width: 300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'var(--light)',
            background: 'var(--status-green)',
            margin: '0 8px 8px 0',
            padding: 3,
            textAlign: 'center',
            fontSize: '1.1rem',
            fontWeight: 600
        }
    };
});

const StyledClose = styled('div')({
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    h2: {
        margin: 0
    }
});

const StyledCloseButton = styled('button')({
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'var(--light)',
    span: {
        textTransform: 'uppercase'
    }
});

const PlatformColours: React.FC = () => {
    return (
        <section>
            <h3>Platform Colours</h3>
            <div className="boxes">
                <div className="box">No Errors</div>
                <div className="box" style={{ backgroundColor: 'var(--status-yellow)' }}>
                    An Error occurred more than 5 minutes ago
                </div>
                <div className="box" style={{ backgroundColor: 'var(--status-red)' }}>
                    Error(s) on the platform
                </div>
            </div>
        </section>
    );
};

const ErrorMessages: React.FC = () => {
    return (
        <section>
            <h3>Error Messages</h3>
            <table>
                <thead>
                    <tr>
                        <th>Error Title</th>
                        <th>Error Description</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(ERROR_DESCRIPTIONS).map((keyName) => (
                        <tr key={keyName}>
                            <td>{keyName}</td>
                            <td>{ERROR_DESCRIPTIONS[keyName]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    );
};

export const Legend: React.FC = () => {
    const { isLegendActive, setIsLegendActive } = useAppContext();
    const closeButtonRef = React.useRef<HTMLButtonElement>(null);

    useBodyScrollLock(isLegendActive);

    React.useEffect(() => {
        if (isLegendActive && closeButtonRef.current) {
            closeButtonRef.current.focus();
        }
    }, [isLegendActive]);

    React.useEffect(() => {
        const handleClose = (ev: KeyboardEvent) => {
            if (ev.key === 'Escape') {
                setIsLegendActive(false);
            }
        };

        document.addEventListener('keydown', handleClose);

        return () => document.removeEventListener('keydown', handleClose);
    }, [isLegendActive, setIsLegendActive]);

    return (
        <StyledLegend isLegendActive={isLegendActive}>
            <StyledClose>
                <h2>Key</h2>
                <StyledCloseButton ref={closeButtonRef} tabIndex={-1} onClick={() => setIsLegendActive(false)}>
                    <Close />
                    <span>ESC</span>
                </StyledCloseButton>
            </StyledClose>
            <PlatformColours />
            <ErrorMessages />
        </StyledLegend>
    );
};
