import * as React from 'react';

import { useAuthContext } from '../hooks';
import { ComponentWithChildren } from '../types';

export const UnprotectedRoute: React.FC<ComponentWithChildren> = ({ children }) => {
    const { isAuthenticated } = useAuthContext();

    return isAuthenticated ? null : <React.Fragment>{children}</React.Fragment>;
};
