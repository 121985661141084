import { API_ROUTES } from '../constants';
import { Event, ExportLogsData, ExportLogsDataRequest, MonitoringState, Stream } from '../types';
import { ValidationConfig } from '../types/ValidationConfig';
import { errorToString } from '../utilities';
import { Http } from './Http';

export class Monitoring {
    public static async getMonitoringState(): Promise<MonitoringState | undefined> {
        try {
            const res = await Http.get(`${API_ROUTES.MONITORING}/monitoring-state`);

            if (!res.ok) {
                console.error('GET /events', res.statusText);
                return undefined;
            }

            return await res.json();
        } catch (error) {
            console.error(errorToString(error));
            return undefined;
        }
    }

    public static async getEvents(): Promise<Event[] | undefined> {
        try {
            const res = await Http.get(`${API_ROUTES.MONITORING}/events`);

            if (!res.ok) {
                console.error('GET /events', res.statusText);
                return undefined;
            }

            return await res.json();
        } catch (error) {
            console.error(errorToString(error));
            return undefined;
        }
    }

    public static async getStream(eventId: number): Promise<Stream | undefined> {
        try {
            const res = await Http.get(`${API_ROUTES.MONITORING}/events/${eventId}`);

            if (!res.ok) {
                console.error(`GET /events/${eventId}`, res.statusText);
                return undefined;
            }

            return await res.json();
        } catch (error) {
            console.error(errorToString(error));
            return undefined;
        }
    }

    public static async updateValidationConfig(
        isEvent: boolean,
        tournamentOrEventId: string,
        payload: Partial<ValidationConfig>
    ): Promise<boolean> {
        try {
            const route = isEvent ? 'event' : 'tournament';
            const res = await Http.put(`${API_ROUTES.MONITORING}/validation-config/${route}/${tournamentOrEventId}`, {
                options: {
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                },
                withAuth: true
            });

            return res.ok;
        } catch (error) {
            return false;
        }
    }

    public static async getExportLogsData(): Promise<ExportLogsData[]> {
        try {
            const res = await Http.get(`${API_ROUTES.MONITORING}/export-logs`);
            return res.ok ? await res.json() : [];
        } catch (error) {
            return [];
        }
    }

    public static async getValidationConfig(isEvent: boolean, tournamentOrEventId: string): Promise<ValidationConfig | undefined> {
        try {
            const route = isEvent ? 'event' : 'tournament';
            const res = await Http.get(`${API_ROUTES.MONITORING}/validation-config/${route}/${tournamentOrEventId}`);

            if (res.ok) {
                return await res.json();
            }
        } catch (error) {
            return undefined;
        }
    }

    public static async createExportLogsData(payload: ExportLogsDataRequest): Promise<ExportLogsData | string> {
        const message = 'Failed to request export logs';

        try {
            const res = await Http.post(`${API_ROUTES.MONITORING}/export-logs`, {
                options: {
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                },
                withAuth: true
            });

            const data = await res.json();

            if (res.ok) {
                return data;
            } else {
                const { error = message } = data ?? {};
                return error;
            }
        } catch {
            return message;
        }
    }
}
