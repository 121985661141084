import React from 'react';

import { Event } from '../types';

/**
 * @returns A list of Tournaments filtered by the search value
 */
export const useSearchTournament = (dgeEvents: Event[], searchValue: string) => {
    const tournaments = React.useMemo(() => {
        const uniqueTournaments = new Map<number, string>();

        dgeEvents.forEach(({ tournament: { id, name } }) => {
            if (searchValue && searchValue.trim() !== '') {
                const regex = new RegExp(searchValue, 'ig');

                if (regex.test(id.toString()) || regex.test(name)) {
                    uniqueTournaments.set(id, name);
                }
            } else {
                uniqueTournaments.set(id, name);
            }
        });

        return Array.from(uniqueTournaments.entries());
    }, [dgeEvents, searchValue]);

    return tournaments;
};
