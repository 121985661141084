import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ErrorBoundary } from './components';
import { Root } from './containers';
import { AppContextProvider, AuthContextProvider } from './contexts';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ErrorBoundary>
                <AuthContextProvider>
                    <AppContextProvider>
                        <Root />
                    </AppContextProvider>
                </AuthContextProvider>
            </ErrorBoundary>
        </BrowserRouter>
    );
};

export default App;
