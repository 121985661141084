import create from 'zustand';

import { BaseOverlayProps } from '../types';

interface State extends Pick<BaseOverlayProps, 'isOpen'> {}

interface Actions extends Omit<BaseOverlayProps, 'isOpen'> {}

const INITIAL_STATE: State = {
    isOpen: false
};

export const useDrawer = create<State & Actions>((set, get) => ({
    ...INITIAL_STATE,
    closeOverlay: () => {
        set({ isOpen: false });
    },
    openOverlay: () => {
        set({
            isOpen: true
        });
    }
}));
