import styled from '@emotion/styled';
import React from 'react';

import { useAppContext } from '../hooks';

const StyledAlert = styled('div')({
    width: '100%',
    color: 'var(--font-color)',
    marginBottom: 24,
    padding: 20,
    background: 'var(--status-red)',
    borderRadius: 3,
    fontSize: '1.1rem',
    textAlign: 'center'
});

export const Alert: React.FC = () => {
    const { isMonitoringApiDown } = useAppContext();

    if (!isMonitoringApiDown) {
        return null;
    }

    return <StyledAlert>No Response from the Monitoring API</StyledAlert>;
};
