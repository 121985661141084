import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TopBar } from '../components';
import { useAppContext } from '../hooks';
import { dashBoardService } from '../services';
import { Tournament } from '../types';

const regex = new RegExp(/^\/tournaments\/(\d+)?$/);

export const TopBarContainer: React.FC = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { dgeEvents, platformStatus } = useAppContext();
    const [tournament, setTournament] = React.useState<Tournament>();

    React.useEffect(() => {
        if (pathname === '/') {
            setTournament(undefined);
        }
    }, [pathname]);

    React.useEffect(() => {
        const tournamentId = regex.exec(pathname)?.[1];

        if (tournamentId) {
            const tournamentFromEvent = dashBoardService.getTournamentFromEvents(+tournamentId, dgeEvents);

            if (!tournamentFromEvent) {
                navigate('/');
            } else {
                setTournament(tournamentFromEvent);
            }
        }
    }, [dgeEvents, navigate, pathname]);

    return <TopBar status={platformStatus} tournamentName={tournament?.name} href="/" />;
};
