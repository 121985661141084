import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from '@mui/material';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { ValidationType } from '../../../enums';
import { pruneInvalidValues } from '../../../utilities';
import { ErrorLabel } from '../../ErrorLabel';
import { useValidationConfig } from '../stores';
import { ValidationFormSchema } from './types';

export const UpdateForm: React.FC = () => {
    const { closeOverlay, submit, initialValues, toggleIsUpdateFormOpen, validationConfigNotFound } = useValidationConfig();
    const labelType = initialValues.eventOrTournament === ValidationType.EVENT ? 'event' : 'tournament';
    const formik = useFormik<ValidationFormSchema>({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const { eventOrTournament, eventOrTournamentId, ...rest } = values;
            const payload = pruneInvalidValues(rest as Record<string, unknown>);

            if (Object.keys(payload).length) {
                const response = submit(values);
                formik.resetForm();
                toast.success('Validation configuration updated.');

                const isSuccess = await response;
                if (!isSuccess) {
                    toast.error('Failed to update the validation configuration.');
                }
            } else {
                formik.resetForm();
            }
        },
        onReset: () => {
            closeOverlay();
        }
    });

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, translateX: -10 }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.8, type: 'spring' }}
            >
                <DialogContent>
                    <Box mb={2}>
                        <DialogContentText>
                            {validationConfigNotFound ? 'Define' : 'Modify'} the settings that this {labelType} will be validated against.
                        </DialogContentText>
                    </Box>
                    <Box sx={{ width: 150 }}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="validationType">Type</InputLabel>
                            <Select
                                labelId="validationType"
                                label="type"
                                name="eventOrTournament"
                                value={formik.values.eventOrTournament}
                                onChange={formik.handleChange}
                                disabled
                            >
                                <MenuItem value={ValidationType.EVENT}>Event</MenuItem>
                                <MenuItem value={ValidationType.TOURNAMENT}>Tournament</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="eventOrTournamentId"
                        label={formik.values.eventOrTournament === ValidationType.EVENT ? 'Event ID' : 'Tournament ID'}
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.eventOrTournamentId}
                        fullWidth
                        variant="standard"
                        disabled
                    />
                    {formik.errors.eventOrTournamentId && formik.touched.eventOrTournamentId && (
                        <ErrorLabel>{formik.errors.eventOrTournamentId}</ErrorLabel>
                    )}
                    <TextField
                        autoFocus
                        margin="dense"
                        name="variants"
                        label="Variants (Primary + Backup)"
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values.variants}
                        fullWidth
                        variant="standard"
                    />
                    {formik.errors.variants && formik.touched.variants && <ErrorLabel>{formik.errors.variants}</ErrorLabel>}
                    <Box mb={1} mt={1}>
                        <FormGroup style={{ flexDirection: 'row' }}>
                            <FormControlLabel
                                control={<Switch name="captions" checked={formik.values.captions} onChange={formik.handleChange} />}
                                label="Captions"
                            />
                            <FormControlLabel
                                control={<Switch name="withAlerts" checked={formik.values.withAlerts} onChange={formik.handleChange} />}
                                label="With Slack Alerts"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        name="withDiscontinuityAlert"
                                        checked={formik.values.withDiscontinuityAlert}
                                        onChange={formik.handleChange}
                                    />
                                }
                                label="With Discontinuity Alert"
                            />
                        </FormGroup>
                    </Box>
                    <TextField
                        margin="dense"
                        name="audioTracks"
                        label="Audio tracks (Primary + Backup)"
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values.audioTracks}
                        fullWidth
                        variant="standard"
                    />
                    {formik.errors.audioTracks && formik.touched.audioTracks && <ErrorLabel>{formik.errors.audioTracks}</ErrorLabel>}
                    <TextField
                        margin="dense"
                        name="variantTargetDuration"
                        label="Video target duration"
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values.variantTargetDuration}
                        fullWidth
                        variant="standard"
                    />
                    {formik.errors.variantTargetDuration && formik.touched.variantTargetDuration && (
                        <ErrorLabel>{formik.errors.variantTargetDuration}</ErrorLabel>
                    )}
                    <TextField
                        margin="dense"
                        name="audioVariantTargetDuration"
                        label="Audio target duration"
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values.audioVariantTargetDuration}
                        fullWidth
                        variant="standard"
                    />
                    {formik.errors.audioVariantTargetDuration && formik.touched.audioVariantTargetDuration && (
                        <ErrorLabel>{formik.errors.audioVariantTargetDuration}</ErrorLabel>
                    )}
                    <TextField
                        margin="dense"
                        name="segmentToleranceUpperLimit"
                        label="Target duration upper tolerance limit"
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values.segmentToleranceUpperLimit}
                        fullWidth
                        variant="standard"
                    />
                    {formik.errors.segmentToleranceUpperLimit && formik.touched.segmentToleranceUpperLimit && (
                        <ErrorLabel>{formik.errors.segmentToleranceUpperLimit}</ErrorLabel>
                    )}
                    <TextField
                        margin="dense"
                        name="segmentToleranceLowerLimit"
                        label="Target duration lower tolerance limit"
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values.segmentToleranceLowerLimit}
                        fullWidth
                        variant="standard"
                    />
                    {formik.errors.segmentToleranceLowerLimit && formik.touched.segmentToleranceLowerLimit && (
                        <ErrorLabel>{formik.errors.segmentToleranceLowerLimit}</ErrorLabel>
                    )}
                    <TextField
                        margin="dense"
                        name="slackChannel"
                        label="Slack Channel"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.slackChannel}
                        fullWidth
                        variant="standard"
                    />
                    {formik.errors.slackChannel && formik.touched.slackChannel && <ErrorLabel>{formik.errors.slackChannel}</ErrorLabel>}
                    <FormControlLabel
                        control={
                            <Switch
                                name="sendStreamingAlertsWithCustomSlackChannel"
                                checked={formik.values.sendStreamingAlertsWithCustomSlackChannel}
                                onChange={formik.handleChange}
                            />
                        }
                        label="Keep sending unfiltered alerts to #dev_doris_alerts"
                    />
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Box>
                        <Button color="info" onClick={() => toggleIsUpdateFormOpen(false)}>
                            Back
                        </Button>
                    </Box>
                    <Box>
                        <Button color="error" onClick={() => formik.resetForm()}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="success" onClick={() => formik.handleSubmit()}>
                            {validationConfigNotFound ? 'Create' : 'Update'}
                        </Button>
                    </Box>
                </DialogActions>
            </motion.div>
        </AnimatePresence>
    );
};

const validationSchema: Yup.SchemaOf<ValidationFormSchema> = Yup.object().shape({
    audioTracks: Yup.number().min(0),
    audioVariantTargetDuration: Yup.number().min(0),
    captions: Yup.boolean(),
    eventOrTournament: Yup.string().required(),
    eventOrTournamentId: Yup.string()
        .required()
        .matches(/^[0-9]+$/, 'Must be a number'),
    sendStreamingAlertsWithCustomSlackChannel: Yup.boolean(),
    segmentToleranceLowerLimit: Yup.number().min(0),
    segmentToleranceUpperLimit: Yup.number().min(0),
    slackChannel: Yup.string(),
    variantTargetDuration: Yup.number().min(0),
    variants: Yup.number().min(0),
    withAlerts: Yup.boolean(),
    withDiscontinuityAlert: Yup.boolean(),
    withSegmentHttpInspection: Yup.boolean()
});
