import * as React from 'react';

import { AlertTypes } from '../../enums';
import { useAuthContext } from '../../hooks';
import { Monitoring, dashBoardService } from '../../services';
import { INITIAL_STATE } from './constants';
import { AppContextType, FetchState, Provider } from './types';

export const AppContext = React.createContext<AppContextType>(INITIAL_STATE);

export const AppContextProvider: React.FC<Provider> = (props: Provider) => {
    const { isAuthenticated } = useAuthContext();
    const [platformStatus, setPlatformStatus] = React.useState(INITIAL_STATE.platformStatus);
    const [dgeEvents, setDgeEvents] = React.useState(INITIAL_STATE.dgeEvents);
    const [monitoringState, setMonitoringState] = React.useState(INITIAL_STATE.monitoringState);
    const [tournamentCards, setTournamentCards] = React.useState(INITIAL_STATE.tournamentCards);
    const [tournamentLinks, setTournamentLinks] = React.useState(INITIAL_STATE.tournamentLinks);
    const [lastUpdateTime, setLastUpdateTime] = React.useState(INITIAL_STATE.lastUpdateTime);
    const [isMonitoringApiDown, setIsMonitoringApiDown] = React.useState(INITIAL_STATE.isMonitoringApiDown);
    const [isLegendActive, setIsLegendActive] = React.useState(INITIAL_STATE.isLegendActive);

    const periodicFetchInterval = React.useRef<number | undefined>();
    const fetchStateRef = React.useRef<FetchState>({ isFetchingEvents: false, isFetchingMonitoringState: false });

    React.useEffect(() => {
        if (isAuthenticated) {
            const fetchState = fetchStateRef.current;

            const fetchEvents = async () => {
                if (!fetchState.isFetchingEvents) {
                    fetchState.isFetchingEvents = true;
                    const events = await Monitoring.getEvents();

                    if (events) {
                        setDgeEvents(events.map((dgeEvent) => ({ ...dgeEvent, dgeUrl: dashBoardService.getDgeLinkFromEvent(dgeEvent) })));
                        setIsMonitoringApiDown(false);
                    } else {
                        setIsMonitoringApiDown(true);
                    }

                    fetchState.isFetchingEvents = false;
                }
            };

            const fetchMonitoringState = async () => {
                if (!fetchState.isFetchingMonitoringState) {
                    fetchState.isFetchingMonitoringState = true;
                    const state = await Monitoring.getMonitoringState();

                    if (state) {
                        setMonitoringState(state);
                        setIsMonitoringApiDown(false);
                    } else {
                        setIsMonitoringApiDown(true);
                    }

                    fetchState.isFetchingMonitoringState = false;
                }
            };

            fetchEvents();
            fetchMonitoringState();

            // Update state every target duration
            periodicFetchInterval.current = window.setInterval(() => {
                fetchEvents();
                fetchMonitoringState();
            }, 6000);

            return () => {
                window.clearInterval(periodicFetchInterval.current);
            };
        }
    }, [isAuthenticated]);

    React.useEffect(() => {
        // Will be undefined if API is down
        if (monitoringState) {
            setLastUpdateTime(Date.now());
        }
    }, [dgeEvents, monitoringState]);

    React.useEffect(() => {
        const tournamentCards = dashBoardService.getTournamentCards(dgeEvents, monitoringState);
        const tournamentLinks = dashBoardService.getTournamentLinks(dgeEvents, monitoringState);
        const platformStatus = dashBoardService.getPlatformStatus(tournamentCards);

        setTournamentCards(tournamentCards);
        setTournamentLinks(tournamentLinks);

        if (isMonitoringApiDown) {
            setPlatformStatus(AlertTypes.RED);
        } else {
            setPlatformStatus(platformStatus);
        }
    }, [dgeEvents, monitoringState, isMonitoringApiDown]);

    const value: AppContextType = {
        ...INITIAL_STATE,
        dgeEvents,
        monitoringState,
        tournamentCards,
        tournamentLinks,
        platformStatus,
        lastUpdateTime,
        isMonitoringApiDown,
        isLegendActive,
        setIsLegendActive
    };

    return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};
