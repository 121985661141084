import styled from '@emotion/styled';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { TournamentCardProps, TournamentCards, TournamentLinks } from '../components';
import { useValidationConfig } from '../components/modals/stores';
import { FilterDashboardTabPanels, ValidationType } from '../enums';
import { useAppContext } from '../hooks';
import { SearchableSearchParams, TournamentLinkRoute } from '../types';
import { getFiltersFromSearchParams } from '../utilities';

const StyledTournaments = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 30,
    '.tournament__heading-link, .tournament__card': {
        margin: '0 16px 30px 0'
    },
    '.tournaments-card-wrapper': {
        marginBottom: 30
    }
});

export const Tournaments: React.FC = () => {
    const [searchParams] = useSearchParams();
    const { tournamentCards, tournamentLinks, dgeEvents } = useAppContext();
    const { openValidationConfigModal } = useValidationConfig((state) => ({
        openValidationConfigModal: state.openOverlay
    }));

    const onEditClick = (id: number) => {
        openValidationConfigModal({ eventOrTournament: ValidationType.TOURNAMENT, eventOrTournamentId: String(id) });
    };

    const { filteredTournamentCards, filteredTournamentLinks } = React.useMemo(() => {
        const filters = getFiltersFromSearchParams(searchParams);
        const tournamentCardsWithIssues = tournamentCards
            .filter((tournamentCard) => tournamentCard.eventIssues.some((eventIssue) => eventIssue.issues.length !== 0))
            .map((tournamentCard) => {
                // Filter out healthy events within a tournament that has issues
                const eventsWithIssues = tournamentCard.eventIssues.filter((eventIssue) => eventIssue.issues.length !== 0);
                return {
                    ...tournamentCard,
                    eventIssues: eventsWithIssues
                };
            });

        if (!filters.length) {
            return {
                filteredTournamentCards: tournamentCardsWithIssues,
                filteredTournamentLinks: tournamentLinks
            };
        }

        const onlyDisplayAlerts = filters.some((filter) => filter.type === FilterDashboardTabPanels.ONLY_ALERTS);
        const expandHealthyAlerts = filters.some((filter) => filter.type === FilterDashboardTabPanels.EXPAND_HEALTHY_TOURNAMENTS);

        const baseTournamentCards = expandHealthyAlerts && !onlyDisplayAlerts ? tournamentCards : tournamentCardsWithIssues;

        if (filters.length === 1 && onlyDisplayAlerts) {
            return {
                filteredTournamentCards: baseTournamentCards,
                filteredTournamentLinks: []
            };
        }

        const tournamentFilters = filters.filter(
            (filter): filter is SearchableSearchParams => filter.type === FilterDashboardTabPanels.TOURNAMENTS
        );
        const eventFilters = filters.filter((filter): filter is SearchableSearchParams => filter.type === FilterDashboardTabPanels.EVENTS);

        const tournamentsFromEvents = dgeEvents
            .filter((event) => eventFilters.some((filter) => filter.id === event.id))
            .map((event) => event.tournament);

        const filteredTournamentCards: TournamentCardProps[] = !tournamentFilters.length && !eventFilters.length ? baseTournamentCards : [];
        const filteredTournamentLinks: TournamentLinkRoute[] = !tournamentFilters.length && !eventFilters.length ? tournamentLinks : [];

        for (const tournamentCard of baseTournamentCards) {
            tournamentFilters.forEach((filter) => {
                if (filter.id === tournamentCard.tournamentId) {
                    filteredTournamentCards.push(tournamentCard);
                }
            });

            tournamentsFromEvents.forEach((tournament) => {
                if (tournament.id === tournamentCard.tournamentId) {
                    filteredTournamentCards.push(tournamentCard);
                }
            });
        }

        if (onlyDisplayAlerts || expandHealthyAlerts) {
            return {
                filteredTournamentCards,
                filteredTournamentLinks: []
            };
        }

        for (const tournamentLink of tournamentLinks) {
            tournamentFilters.forEach((filter) => {
                if (filter.id === tournamentLink.id) {
                    filteredTournamentLinks.push(tournamentLink);
                }
            });

            tournamentsFromEvents.forEach((tournament) => {
                if (tournament.id === tournamentLink.id) {
                    filteredTournamentLinks.push(tournamentLink);
                }
            });
        }

        return {
            filteredTournamentCards,
            filteredTournamentLinks
        };
    }, [searchParams, tournamentCards, tournamentLinks, dgeEvents]);

    return (
        <>
            {!!filteredTournamentCards.length && (
                <StyledTournaments>
                    <TournamentCards tournamentCards={filteredTournamentCards} onEditClick={onEditClick} />
                </StyledTournaments>
            )}
            {!!filteredTournamentLinks.length && (
                <StyledTournaments>
                    <TournamentLinks tournamentLinks={filteredTournamentLinks} onEditClick={onEditClick} />
                </StyledTournaments>
            )}
        </>
    );
};
