import styled from '@emotion/styled';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import * as React from 'react';

import { ExportLogsData } from '../types';
import { FileDownload } from './icons';

interface ExportLogsTableProps {
    data: ExportLogsData[];
}

export const ExportLogsTable: React.FC<ExportLogsTableProps> = ({ data }) => {
    const canDownloadSome = data.some((row) => row.downloadLink != null);

    return (
        <TableContainer>
            <StyledTable sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Requested At</TableCell>
                        <TableCell align="right">Request By</TableCell>
                        <TableCell align="right">Event ID</TableCell>
                        <TableCell align="right">From</TableCell>
                        <TableCell align="right">To</TableCell>
                        <TableCell align="right">Status</TableCell>
                        {canDownloadSome ? <TableCell align="right">Download Link</TableCell> : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.exportTaskId}>
                            <TableCell component="th" scope="row">
                                {dateToIsoString(row.requestedAt)}
                            </TableCell>
                            <TableCell align="right">{row.requestedBy}</TableCell>
                            <TableCell align="right" style={{ maxWidth: 216 }}>
                                {row.eventIds.join(', ')}
                            </TableCell>
                            <TableCell align="right">{dateToIsoString(row.from)}</TableCell>
                            <TableCell align="right">{dateToIsoString(row.to)}</TableCell>
                            <TableCell align="right">{row.status}</TableCell>
                            {canDownloadSome ? (
                                <TableCell align="right">
                                    {row.downloadLink ? (
                                        <IconButton aria-label="download file" href={row.downloadLink} download>
                                            <FileDownload />
                                        </IconButton>
                                    ) : null}
                                </TableCell>
                            ) : null}
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
            {!data.length ? <NoDataToShow /> : null}
        </TableContainer>
    );
};

const NoDataToShow: React.FC = () => {
    return (
        <NoData>
            <h3>No exported logs to show</h3>
        </NoData>
    );
};

const StyledTable = styled(Table)({
    marginBottom: '4rem',
    'thead tr, thead th': {
        backgroundColor: 'var(--primary)',
        color: 'var(--font-color)'
    },
    tbody: {
        'th, td': {
            backgroundColor: 'var(--secondary)',
            color: 'var(--font-color)'
        },
        'tr:last-child td, tr:last-child th': {
            border: 0
        }
    },
    svg: {
        fill: 'var(--font-color)'
    }
});

const NoData = styled('div')({
    textAlign: 'center'
});

const dateToIsoString = (date: string): string => new Date(date).toISOString();
