import styled from '@emotion/styled';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { AlertTypes } from '../enums';
import { getStatusColor } from '../styles';
import { isExternalLink } from '../utilities';
import { CheckCircle, FilledErrorCircle } from './icons';
import { TournamentHeading } from './TournamentHeading';

export interface EventIssueEntry {
    message: string;
    timestamp?: string;
    count?: number;
}

export interface TournamentEventIssue {
    eventId: number;
    /**
     * @example WWE Network
     */
    eventName: string;
    /**
     * @example ["Audio Track Missing", "Invalid Segment Length - 2.000"]
     */
    issues: EventIssueEntry[];
    eventImageUrl?: string;
    withoutEventName?: boolean;
}

export interface TournamentCardProps {
    tournamentId: number;
    eventId?: number;
    title: string;
    href?: string;
    count?: string;
    eventStatus: AlertTypes;
    eventIssues: TournamentEventIssue[];
    dgeUrl?: string;
    onEditClick?: () => void;
}

export interface TournamentCardsProps {
    tournamentCards: TournamentCardProps[];
    onEditClick?: (id: number) => void;
}

interface TournamentCardContentProps {
    href?: string;
    children: React.ReactNode;
}

const StyledTournamentCard = styled('div')((props: { eventStatus: AlertTypes }) => {
    return {
        width: 660,
        backgroundColor: 'var(--primary)',
        borderRadius: 3,
        userSelect: 'none',
        border: '1px solid #1e293b',
        a: {
            textDecoration: 'none',
            color: 'var(--font-color)'
        },
        '.tournament__name, .tournament__count': {
            fontSize: '30px'
        },
        '.tournament__heading': {
            backgroundColor: getStatusColor(props.eventStatus),
            borderRadius: 3,
            border: `1px solid ${getStatusColor(props.eventStatus)}`
        }
    };
});

const StyledTournamentIssues = styled('div')({
    padding: 6
});

const StyledTournamentIssue = styled('div')({
    padding: 6,
    '.tournament-issue__eventName': {
        borderBottom: '1px solid var(--light)',
        paddingBottom: 3,
        marginBottom: 6,
        alignItems: 'center',
        p: {
            margin: 0
        }
    },
    svg: {
        fill: 'var(--status-yellow)',
        marginRight: 6
    },
    img: {
        width: 16,
        height: 16,
        objectFit: 'cover',
        marginRight: 6
    }
});

const StyledIssueContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 3,
    '.message': {
        width: '65%'
    }
});

const StyledIssue = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '.count': {
        backgroundColor: 'var(--status-red)',
        borderRadius: 3,
        marginLeft: 6,
        minWidth: 36,
        padding: 2,
        textAlign: 'center'
    }
});

const EventName = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    span: {
        display: 'flex'
    }
});

const TournamentIssue: React.FC<TournamentEventIssue> = ({ eventName, issues, eventImageUrl, withoutEventName }: TournamentEventIssue) => {
    return (
        <StyledTournamentIssue>
            {!withoutEventName && (
                <StyledIssue className="tournament-issue__eventName">
                    {eventImageUrl && <img src={eventImageUrl} alt={eventName} />}
                    <EventName>
                        <span>{eventName}</span>
                        {issues.length ? null : (
                            <span>
                                <CheckCircle />
                            </span>
                        )}
                    </EventName>
                </StyledIssue>
            )}
            {issues.map((issue, key) => (
                <StyledIssueContainer key={key}>
                    <StyledIssue className="message">
                        <span>
                            <FilledErrorCircle />
                        </span>
                        {issue.message}
                    </StyledIssue>
                    <StyledIssue>
                        {issue.timestamp && <span>{issue.timestamp}</span>}
                        {issue.count !== undefined && <span className="count">{issue.count}</span>}
                    </StyledIssue>
                </StyledIssueContainer>
            ))}
        </StyledTournamentIssue>
    );
};

const TournamentCardContent: React.FC<TournamentCardContentProps> = ({ children, href }: TournamentCardContentProps) => {
    if (!href) {
        return <>{children}</>;
    }

    return isExternalLink(href) ? (
        <a href={href} target="_blank" rel="noreferrer">
            {children}
        </a>
    ) : (
        <Link to={href}>{children}</Link>
    );
};

const TournamentCard: React.FC<TournamentCardProps> = ({
    count,
    eventIssues,
    eventStatus,
    title,
    href,
    onEditClick
}: TournamentCardProps) => {
    return (
        <StyledTournamentCard className="tournament__card" eventStatus={eventStatus}>
            <TournamentHeading name={title} count={count} onEditClick={onEditClick} />
            <TournamentCardContent href={href}>
                <StyledTournamentIssues>
                    {eventIssues.map(({ eventId, eventName, issues, eventImageUrl, withoutEventName }) => (
                        <TournamentIssue
                            key={eventId}
                            eventId={eventId}
                            issues={issues}
                            eventName={eventName}
                            eventImageUrl={eventImageUrl}
                            withoutEventName={withoutEventName}
                        />
                    ))}
                </StyledTournamentIssues>
            </TournamentCardContent>
        </StyledTournamentCard>
    );
};

export const TournamentCards: React.FC<TournamentCardsProps> = ({ tournamentCards, onEditClick }: TournamentCardsProps) => {
    if (!tournamentCards.length) {
        return null;
    }

    return (
        <>
            {tournamentCards.map(({ count, eventIssues, eventStatus, title, href, tournamentId, eventId, dgeUrl }) => (
                <TournamentCard
                    key={eventId ?? tournamentId}
                    href={href ?? dgeUrl}
                    count={count}
                    eventIssues={eventIssues}
                    title={title}
                    eventStatus={eventStatus}
                    tournamentId={tournamentId}
                    onEditClick={onEditClick ? () => onEditClick(eventId ?? tournamentId) : undefined}
                />
            ))}
        </>
    );
};
