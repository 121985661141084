import styled from '@emotion/styled';
import * as React from 'react';

import { TournamentLinkRoute } from '../types';
import { TournamentHeading } from './TournamentHeading';

export interface TournamentLinkProps {
    href: string;
    name: string;
    count?: number;
    onEditClick?: () => void;
}

export interface TournamentLinksProps {
    tournamentLinks: TournamentLinkRoute[];
    onEditClick?: (id: number) => void;
}

const StyledLinkTournamentLink = styled('div')({
    '&:hover, &:focus': {
        '.tournament__heading': {
            backgroundColor: 'var(--dark-green)',
            transform: 'scale(1.02)'
        }
    },
    '.tournament__heading': {
        borderRadius: 3,
        boxShadow: '0 1px 2px var(--tertiary)',
        width: 330,
        transition: 'background-color 0.3s ease, transform 0.3s ease'
    }
});

const TournamentLink: React.FC<TournamentLinkProps> = ({ name, href, count, onEditClick }: TournamentLinkProps) => {
    return (
        <StyledLinkTournamentLink>
            <TournamentHeading name={name} count={count} href={href} onEditClick={onEditClick} />
        </StyledLinkTournamentLink>
    );
};

export const TournamentLinks: React.FC<TournamentLinksProps> = ({ tournamentLinks, onEditClick }: TournamentLinksProps) => {
    if (!tournamentLinks.length) {
        return null;
    }

    return (
        <>
            {tournamentLinks.map(({ name, count, id }) => (
                <TournamentLink
                    key={name}
                    href={`tournaments/${id}`}
                    name={name}
                    count={count}
                    onEditClick={onEditClick ? () => onEditClick(id) : undefined}
                />
            ))}
        </>
    );
};
