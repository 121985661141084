import create from 'zustand';

import { ValidationType } from '../../../enums';
import { Monitoring } from '../../../services';
import { useDrawer } from '../../../stores';
import { BaseOverlayProps, ValidationConfig } from '../../../types';
import { ValidationFormSchema } from '../ValidationConfig/types';

interface State extends Pick<BaseOverlayProps, 'isOpen'> {
    initialValues: ValidationFormSchema;
    isUpdateFormOpen: boolean;
    validationConfigNotFound: boolean;
}

interface OpenModalOptions {
    eventOrTournament: ValidationType;
    eventOrTournamentId: string;
}

interface Actions extends Omit<BaseOverlayProps, 'isOpen'> {
    fetchValidationConfig: (eventOrTournament: string, tournamentOrEventId: string) => Promise<ValidationConfig | undefined>;
    openOverlay: (option?: OpenModalOptions) => void;
    submit: (payload: ValidationFormSchema) => Promise<boolean>;
    toggleIsUpdateFormOpen: (openUpdateForm: boolean) => void;
    toggleValidationConfigNotFound: (validationConfigNotFound: boolean) => void;
}

const INITIAL_STATE: State = {
    initialValues: {
        eventOrTournament: ValidationType.EVENT,
        eventOrTournamentId: '',
        captions: false,
        withAlerts: true,
        withDiscontinuityAlert: true,
        sendStreamingAlertsWithCustomSlackChannel: false
    },
    isOpen: false,
    isUpdateFormOpen: false,
    validationConfigNotFound: false
};

export const useValidationConfig = create<State & Actions>((set, get) => ({
    ...INITIAL_STATE,
    closeOverlay: () => {
        set({ ...INITIAL_STATE });
        useDrawer.setState({ isOpen: false });
    },
    fetchValidationConfig: async (eventOrTournament: string, eventOrTournamentId: string) => {
        const isEvent = eventOrTournament === ValidationType.EVENT;
        const data = await Monitoring.getValidationConfig(isEvent, eventOrTournamentId);
        const { initialValues } = get();

        if (data) {
            // ensure booleans always have a default value for the Switch component
            const {
                captions = initialValues.captions,
                withAlerts = initialValues.withAlerts,
                withDiscontinuityAlert = initialValues.withDiscontinuityAlert,
                sendStreamingAlertsWithCustomSlackChannel = initialValues.sendStreamingAlertsWithCustomSlackChannel,
                ...rest
            } = data;
            set({
                isOpen: true,
                isUpdateFormOpen: true,
                initialValues: {
                    eventOrTournament,
                    eventOrTournamentId,
                    captions,
                    withAlerts,
                    withDiscontinuityAlert,
                    sendStreamingAlertsWithCustomSlackChannel,
                    ...rest
                }
            });
        } else {
            set({
                isOpen: true,
                validationConfigNotFound: true,
                initialValues: { ...initialValues, eventOrTournament, eventOrTournamentId }
            });
        }

        return data;
    },
    openOverlay: (option?: OpenModalOptions) => {
        if (option) {
            const { eventOrTournament, eventOrTournamentId } = option;
            const { fetchValidationConfig } = get();
            fetchValidationConfig(eventOrTournament, eventOrTournamentId);
        } else {
            set({ isOpen: true });
        }
    },
    submit: async (payload: ValidationFormSchema) => {
        const { eventOrTournament, eventOrTournamentId, ...rest } = payload;
        const isEvent = eventOrTournament === ValidationType.EVENT;

        for (const key in rest) {
            if ((rest as any)[key] === '') {
                // clear empty fields, so they are removed from the DB (/PUT)
                delete (rest as any)[key];
            }
        }

        return Monitoring.updateValidationConfig(isEvent, eventOrTournamentId, rest);
    },
    toggleIsUpdateFormOpen: (openUpdateForm: boolean) => set({ isUpdateFormOpen: openUpdateForm }),
    toggleValidationConfigNotFound: (validationConfigNotFound: boolean) => set({ validationConfigNotFound })
}));
