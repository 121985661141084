import * as React from 'react';

import { useAuthContext } from '../hooks';
import { ComponentWithChildren } from '../types';

export const ProtectedRoute: React.FC<ComponentWithChildren> = ({ children }) => {
    const { isAuthenticated } = useAuthContext();

    return isAuthenticated ? <React.Fragment>{children}</React.Fragment> : null;
};
