import styled from '@emotion/styled';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../enums';

import { useAppContext } from '../hooks';
import { DorisAvatar } from './DorisAvatar';
import { FileDownload, Filter, Help, Spanner } from './icons';
import { useFilterDashboard, useValidationConfig } from './modals/stores';

interface DrawerProps {
    isOpen: boolean;
    onClose: () => void;
}

const DRAWER_WIDTH = 256;

const StyledDrawer = styled(MuiDrawer)({
    width: DRAWER_WIDTH,
    '& .MuiDrawer-paper': {
        backgroundColor: 'var(--secondary)',
        width: DRAWER_WIDTH
    }
});

const DrawerHeader = styled('header')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px 0',
    height: 90
});

const DrawerBody = styled('div')({
    padding: '0 12px',
    color: 'var(--light)',
    svg: {
        fill: 'var(--light)'
    }
});

export const Drawer: React.FC<DrawerProps> = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const isIndexRoute = pathname === '/';
    const { setIsLegendActive } = useAppContext();
    const { openFilterDashboardModal } = useFilterDashboard((state) => ({
        openFilterDashboardModal: state.openOverlay
    }));
    const { openValidationConfigModal } = useValidationConfig((state) => ({
        openValidationConfigModal: state.openOverlay
    }));

    const showLegend = () => {
        onClose();
        setIsLegendActive(true);
    };

    const navigateToLogsExport = () => {
        onClose();
        navigate(Routes.EXPORT_LOGS);
    };

    return (
        <StyledDrawer variant="temporary" open={isOpen} onClose={onClose}>
            <DrawerHeader>
                <DorisAvatar />
            </DrawerHeader>
            <DrawerBody>
                <List>
                    <ListItemButton onClick={navigateToLogsExport}>
                        <ListItemIcon>
                            <FileDownload />
                        </ListItemIcon>
                        <ListItemText primary="Export Logs" />
                    </ListItemButton>
                    <ListItemButton onClick={openFilterDashboardModal} disabled={!isIndexRoute}>
                        <ListItemIcon>
                            <Filter />
                        </ListItemIcon>
                        <ListItemText primary="Filter" />
                    </ListItemButton>
                    <ListItemButton onClick={showLegend}>
                        <ListItemIcon>
                            <Help />
                        </ListItemIcon>
                        <ListItemText primary="Help" />
                    </ListItemButton>
                    <ListItemButton onClick={() => openValidationConfigModal()}>
                        <ListItemIcon>
                            <Spanner />
                        </ListItemIcon>
                        <ListItemText primary="Validation Configuration" />
                    </ListItemButton>
                </List>
            </DrawerBody>
        </StyledDrawer>
    );
};
