export const ERROR_DESCRIPTIONS: Record<string, string> = {
    'Event not monitored': "The Event is live in DGE but the monitoring tool isn't currently monitoring the event",
    'DVR window length has shrunk': 'The DVR window length has shrunk by more than one target duration',
    'Segments are not keyframe aligned': 'The differences in presentation timestamps (PTS) values between the keyframes are not identical',
    'Segment keyframe intervals are not equal to 2': 'The calculated keyframe interval (KFI) does not match the recommended value of 2',
    'Unable to decode fragment': 'We were unable to decode a fragment or unable to download a fragment (likely) due to network issues',
    'HTTP Status Code non-200': 'Received a non 200 OK response while fetching a playlist',
    'HTTP Status Code No Server Response': 'No response received while fetching a playlist',
    'DGE HTTP Status Code non-200': 'Received a non 200 OK response while querying the DGE',
    'DGE HTTP Status Code No Server Response': 'No response received while querying the DGE',
    'No captions found in the stream': 'We expected captions in the stream but none were advertised in the manifest',
    'Invalid number of audio tracks in the stream': 'The number of audio tracks in the stream does not match the expected value',
    'Audio segment duration invalid': 'The calculated segment duration does not match the expected value',
    'Segments not found in Playlist': 'No segments were found in a Level playlist',
    'Invalid target duration length': 'The calculated target duration does not match the expected value',
    'Invalid number of variants in the stream': 'The calculated number of variants in the stream does not match the expected value',
    'Video segment duration invalid': 'The calculated segment duration does not match the expected value',
    'Segment framerate does not match with the validation configuration': 'The decoded framerate does not match the expected value'
};
