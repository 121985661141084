import { FilterDashboardTabPanels } from '../enums';
import { FilterDashboardSearchParams } from '../types';

export const SEARCH_PARAMS_DELIMITER = '_';

export const getFiltersFromSearchParams = (urlSearchParams?: URLSearchParams): FilterDashboardSearchParams[] => {
    const filterType: FilterDashboardSearchParams[] = [];
    const params = urlSearchParams ?? new URLSearchParams(window.location.search);
    const tournaments = params.get('tournaments')?.split(',').filter(Boolean) ?? [];
    const events = params.get('events')?.split(',').filter(Boolean) ?? [];
    const onlyAlerts = params.get('onlyAlerts');
    const expandHealthyTournaments = params.get('expandHealthyTournaments');

    events.forEach((event) => {
        const [id, label] = event.split(SEARCH_PARAMS_DELIMITER);
        filterType.push({
            id: parseInt(id, 10),
            label,
            type: FilterDashboardTabPanels.EVENTS
        });
    });

    tournaments.forEach((tournament) => {
        const [id, label] = tournament.split(SEARCH_PARAMS_DELIMITER);

        filterType.push({
            id: parseInt(id, 10),
            label,
            type: FilterDashboardTabPanels.TOURNAMENTS
        });
    });

    if (onlyAlerts != null) {
        filterType.push({
            type: FilterDashboardTabPanels.ONLY_ALERTS
        });
    }

    if (expandHealthyTournaments != null) {
        filterType.push({
            type: FilterDashboardTabPanels.EXPAND_HEALTHY_TOURNAMENTS
        });
    }

    return filterType;
};
