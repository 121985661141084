import { URLSearchParamsInit } from 'react-router-dom';
import create from 'zustand';

import { FilterDashboardTabPanels } from '../../../enums';
import { useDrawer } from '../../../stores';
import { BaseOverlayProps, FilterDashboardSearchParams } from '../../../types';
import { SEARCH_PARAMS_DELIMITER, getFiltersFromSearchParams, isSearchableSearchParams } from '../../../utilities';

interface State extends Pick<BaseOverlayProps, 'isOpen'> {
    activeTabPanel: FilterDashboardTabPanels;
    filters: FilterDashboardSearchParams[];
    searchValue: string;
}

interface Actions extends Omit<BaseOverlayProps, 'isOpen'> {
    setActiveTabPanel: (_: React.SyntheticEvent, newValue: FilterDashboardTabPanels) => void;
    setSearchValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
    toggleFilter: (searchParam: FilterDashboardSearchParams) => void;
    applySearchParams: (setSearchParams: (searchParams: URLSearchParamsInit) => void) => void;
}

const INITIAL_STATE: State = {
    activeTabPanel: FilterDashboardTabPanels.TOURNAMENTS,
    filters: getFiltersFromSearchParams(),
    isOpen: false,
    searchValue: ''
};

export const useFilterDashboard = create<State & Actions>((set, get) => ({
    ...INITIAL_STATE,
    applySearchParams: (setSearchParams) => {
        const { filters, closeOverlay } = get();
        const params: Record<string, string> = {
            events: '',
            tournaments: '',
            onlyAlerts: 'false',
            expandHealthyTournaments: 'false'
        };

        for (const filter of filters) {
            if (filter.type === FilterDashboardTabPanels.EVENTS) {
                params.events += `${filter.id}${SEARCH_PARAMS_DELIMITER}${filter.label},`;
            } else if (filter.type === FilterDashboardTabPanels.TOURNAMENTS) {
                params.tournaments += `${filter.id}${SEARCH_PARAMS_DELIMITER}${filter.label},`;
            } else if (filter.type === FilterDashboardTabPanels.ONLY_ALERTS) {
                params.onlyAlerts = '';
            } else if (filter.type === FilterDashboardTabPanels.EXPAND_HEALTHY_TOURNAMENTS) {
                params.expandHealthyTournaments = '';
            }
        }

        if (params.events === '') {
            delete params.events;
        }

        if (params.tournaments === '') {
            delete params.tournaments;
        }

        if (params.onlyAlerts === 'false') {
            delete params.onlyAlerts;
        }

        if (params.expandHealthyTournaments === 'false') {
            delete params.expandHealthyTournaments;
        }

        setSearchParams(params);
        closeOverlay();
    },
    closeOverlay: () => {
        set({ isOpen: false });
        useDrawer.setState({ isOpen: false });
    },
    openOverlay: () => {
        set({
            ...INITIAL_STATE,
            filters: getFiltersFromSearchParams(),
            isOpen: true
        });
    },
    setActiveTabPanel: (_, panel) => {
        set({ activeTabPanel: panel, searchValue: '' });
    },
    setSearchValue: (event) => {
        set({ searchValue: event.target.value });
    },
    toggleFilter: (searchParam: FilterDashboardSearchParams) => {
        const { filters } = get();

        switch (searchParam.type) {
            case FilterDashboardTabPanels.EVENTS:
            case FilterDashboardTabPanels.TOURNAMENTS: {
                const { id, label, type } = searchParam;
                const indexOfFilter = filters
                    .filter(isSearchableSearchParams)
                    .findIndex((filter) => filter.type === type && filter.id === id && filter.label === label);

                if (indexOfFilter !== -1) {
                    const nextFilters = [...filters];
                    nextFilters.splice(indexOfFilter, 1);
                    set({ filters: [...nextFilters] });
                } else {
                    set({ filters: [...filters, { id, label, type }] });
                }

                break;
            }

            case FilterDashboardTabPanels.ONLY_ALERTS:
            case FilterDashboardTabPanels.EXPAND_HEALTHY_TOURNAMENTS: {
                const { type } = searchParam;
                const hasFilter = filters.find((filter) => filter.type === type);

                if (hasFilter) {
                    set({ filters: filters.filter((filter) => filter.type !== type) });
                } else {
                    set({ filters: [...filters, { type }] });
                }
                break;
            }
        }
    }
}));
