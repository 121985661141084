import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { API_ROUTES } from '../constants';
import { Auth } from '../services';
import { Logout, Menu } from './icons';

const StyledNav = styled('nav')({
    backgroundColor: 'var(--secondary)',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    minHeight: 24,
    color: 'var(--font-color)',
    padding: '4px 16px'
});

const StyledLink = styled(Link)({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'var(--font-color)',
    userSelect: 'none',
    svg: {
        fill: 'var(--font-color)',
        marginRight: 6
    },
    '&:hover': {
        color: 'var(--status-yellow)',
        cursor: 'pointer',
        svg: {
            fill: 'var(--status-yellow)'
        }
    }
});

export interface NavBarProps {
    onMenuClick: () => void;
}

export const NavBar: React.FC<NavBarProps> = ({ onMenuClick }) => {
    return (
        <StyledNav>
            <IconButton onClick={onMenuClick} title="Menu">
                <Menu fill={'var(--font-color)'} />
            </IconButton>
            <StyledLink to={API_ROUTES.LOGOUT} onClick={Auth.clearSession}>
                <Logout />
                <span>Log out</span>
            </StyledLink>
        </StyledNav>
    );
};
