import { Box, Button, DialogActions, DialogContent, DialogContentText, TextField, Typography } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormikProps } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import moment, { Moment } from 'moment';
import * as React from 'react';

import { ErrorLabel } from '../../ErrorLabel';
import { useRequestLogs } from '../stores';
import { ExportLogsDataFormSchema } from './types';

interface ManualEventInputProps {
    closeOverlay: () => void;
    formik: FormikProps<ExportLogsDataFormSchema>;
    maxDateTime: Moment;
    minDateTime: Moment;
}

export const ManualEventInput: React.FC<ManualEventInputProps> = ({ formik, maxDateTime, minDateTime, closeOverlay }) => {
    const { setIsSearchByTournamentOpen } = useRequestLogs();
    const eventCount = formik.values.eventIds.split(',').filter((n) => !!n && n != null).length;

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, translateX: -10 }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.8, type: 'spring' }}
            >
                <DialogContent>
                    <Box mb={2}>
                        <DialogContentText>Define the parameters from which the logs export will be created from.</DialogContentText>
                        <DialogContentText>You can provide Event ID(s) or search by Tournament.</DialogContentText>
                    </Box>
                    <Box marginBottom={2}>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="eventIds"
                            label="Event IDs"
                            placeholder="e.g. 1001, 1002, 1003"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.eventIds}
                            fullWidth
                            variant="standard"
                        />
                        <Typography color={'GrayText'} textAlign={'right'} fontSize={'0.8rem'}>
                            {eventCount} event(s)
                        </Typography>
                        {formik.errors.eventIds && formik.touched.eventIds && <ErrorLabel>{formik.errors.eventIds}</ErrorLabel>}
                    </Box>
                    <Box>
                        <Button color="info" variant="contained" onClick={() => setIsSearchByTournamentOpen(true)}>
                            Or Search by Tournament
                        </Button>
                    </Box>
                    <Box marginBottom={4}>
                        <TextField
                            margin="dense"
                            name="requestedBy"
                            label="Requested By"
                            placeholder="Your name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.requestedBy}
                            fullWidth
                            variant="standard"
                        />
                        {formik.errors.requestedBy && formik.touched.requestedBy && <ErrorLabel>{formik.errors.requestedBy}</ErrorLabel>}
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Box marginBottom="4px">
                                    <DateTimePicker
                                        renderInput={(props) => <TextField {...props} />}
                                        minDateTime={moment(minDateTime) as any}
                                        maxDateTime={maxDateTime}
                                        InputProps={{ name: 'from' }}
                                        label="From"
                                        value={formik.touched.from ? formik.values.from : null}
                                        onClose={() => formik.setFieldTouched('from', true)}
                                        onChange={(value: Moment | null) => {
                                            if (value) {
                                                formik.setFieldValue('from', value);
                                            }
                                        }}
                                    />
                                </Box>
                            </LocalizationProvider>
                            {formik.errors.from && formik.touched.from && (
                                <ErrorLabel style={{ maxWidth: 230 }}>{formik.errors.from}</ErrorLabel>
                            )}
                        </Box>
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Box marginBottom="4px">
                                    <DateTimePicker
                                        renderInput={(props) => <TextField {...props} />}
                                        minDateTime={moment(formik.values.from) as any}
                                        maxDateTime={maxDateTime}
                                        InputProps={{ name: 'to' }}
                                        label="To"
                                        value={formik.touched.to ? formik.values.to : null}
                                        onClose={() => formik.setFieldTouched('to', true)}
                                        onChange={(value: Moment | null) => {
                                            if (value) {
                                                formik.setFieldValue('to', value);
                                            }
                                        }}
                                    />
                                </Box>
                            </LocalizationProvider>
                            {formik.errors.to && formik.touched.to && <ErrorLabel style={{ maxWidth: 230 }}>{formik.errors.to}</ErrorLabel>}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box>
                        <Button color="error" onClick={closeOverlay}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="success" onClick={() => formik.handleSubmit()}>
                            Request Logs
                        </Button>
                    </Box>
                </DialogActions>
            </motion.div>
        </AnimatePresence>
    );
};
