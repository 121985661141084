import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import * as React from 'react';
import * as Yup from 'yup';

import { ValidationType } from '../../../enums';
import { ErrorLabel } from '../../ErrorLabel';
import { useValidationConfig } from '../stores';

export type SearchFormSchema = {
    eventOrTournament: string;
    eventOrTournamentId: string;
};

export const SearchForm: React.FC = () => {
    const {
        closeOverlay,
        fetchValidationConfig,
        validationConfigNotFound,
        toggleIsUpdateFormOpen,
        toggleValidationConfigNotFound,
        initialValues
    } = useValidationConfig();

    const formik = useFormik<SearchFormSchema>({
        initialValues: {
            eventOrTournament: initialValues.eventOrTournament,
            eventOrTournamentId: initialValues.eventOrTournamentId
        },
        validationSchema,
        onSubmit: (values) => {
            const { eventOrTournament, eventOrTournamentId } = values;
            fetchValidationConfig(eventOrTournament, eventOrTournamentId);
        },
        onReset: () => {
            closeOverlay();
        }
    });

    React.useEffect(() => {
        if (formik.dirty) {
            toggleValidationConfigNotFound(false);
        }
    }, [formik.values.eventOrTournament, formik.values.eventOrTournamentId, toggleValidationConfigNotFound, formik.dirty]);

    return (
        <>
            <DialogContent>
                <Box mb={2}>
                    <DialogContentText>Search for an event or tournament validation configuration.</DialogContentText>
                </Box>
                <Box mb={3}>
                    <Box sx={{ width: 150 }}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="validationType">Type</InputLabel>
                            <Select
                                autoFocus
                                labelId="validationType"
                                label="type"
                                name="eventOrTournament"
                                value={formik.values.eventOrTournament}
                                onChange={formik.handleChange}
                            >
                                <MenuItem value={ValidationType.EVENT}>Event</MenuItem>
                                <MenuItem value={ValidationType.TOURNAMENT}>Tournament</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="eventOrTournamentId"
                        label={formik.values.eventOrTournament === ValidationType.EVENT ? 'Event ID' : 'Tournament ID'}
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.eventOrTournamentId}
                        fullWidth
                        variant="standard"
                    />
                    {formik.errors.eventOrTournamentId && formik.touched.eventOrTournamentId && (
                        <ErrorLabel>{formik.errors.eventOrTournamentId}</ErrorLabel>
                    )}
                </Box>
                {validationConfigNotFound ? (
                    <motion.div
                        initial={{ opacity: 0, translateY: -6 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        transition={{ duration: 0.8, type: 'spring' }}
                    >
                        <Button color="info" variant="contained" onClick={() => toggleIsUpdateFormOpen(true)}>
                            Config not found, Create one
                        </Button>
                    </motion.div>
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={() => formik.resetForm()}>
                    Cancel
                </Button>
                <Button variant="contained" color="success" onClick={() => formik.handleSubmit()}>
                    Search
                </Button>
            </DialogActions>
        </>
    );
};

const validationSchema: Yup.SchemaOf<SearchFormSchema> = Yup.object().shape({
    eventOrTournament: Yup.string().required(),
    eventOrTournamentId: Yup.string()
        .required()
        .matches(/^[0-9]+$/, 'Must be a number')
});
