import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';

import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import React from 'react';
import { useAppContext, useSearchTournament } from '../../../hooks';

import { useFilterDashboard } from '../stores';
import { FilterDashboardTabPanels } from '../../../enums';
import { FilterDashboardSearchParams, SearchableSearchParams } from '../../../types';
import { SEARCHABLE_FILTERS } from '../../../constants';

export const FilterDashboard: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { dgeEvents } = useAppContext();
    const {
        applySearchParams,
        isOpen,
        closeOverlay,
        filters,
        toggleFilter,
        activeTabPanel,
        setActiveTabPanel,
        searchValue,
        setSearchValue
    } = useFilterDashboard();
    const tournaments = useSearchTournament(dgeEvents, searchValue);

    const events = React.useMemo(() => {
        const uniqueEvents = new Map<number, string>();

        dgeEvents.forEach(({ id, title }) => {
            if (searchValue && searchValue.trim() !== '') {
                const regex = new RegExp(searchValue, 'ig');

                if (regex.test(id.toString()) || regex.test(title)) {
                    uniqueEvents.set(id, title);
                }
            } else {
                uniqueEvents.set(id, title);
            }
        });

        return Array.from(uniqueEvents.entries());
    }, [dgeEvents, searchValue]);

    const isSearchHidden = SEARCHABLE_FILTERS.includes(activeTabPanel);

    return (
        <StyledDialog open={isOpen} onClose={closeOverlay} fullWidth maxWidth="md">
            <DialogTitle bgcolor="var(--light)">Add or Remove Filters</DialogTitle>
            <ActionSection paddingY={1} paddingX={3}>
                <ActionableFilters filters={filters} toggleFilter={toggleFilter} />
                <Box>
                    <Button
                        disabled={!Array.from(searchParams).length && !filters.length}
                        type="button"
                        variant="contained"
                        color="success"
                        onClick={() => applySearchParams(setSearchParams)}
                    >
                        Apply
                    </Button>
                </Box>
            </ActionSection>
            <Content>
                <Grid container spacing={2}>
                    <LeftGrid item xs={6} md={3}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={activeTabPanel}
                            onChange={setActiveTabPanel}
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            <Tab label="Tournaments" />
                            <Tab label="Events" />
                            <Tab label="Only Alerts" />
                            <Tab label="Expand Healthy Tournaments" />
                        </Tabs>
                    </LeftGrid>
                    <Grid item xs={6} md={9}>
                        {isSearchHidden && (
                            <TextField
                                id="search"
                                label="Search"
                                value={searchValue}
                                onChange={setSearchValue}
                                variant="standard"
                                fullWidth
                            />
                        )}
                        <TabPanel value={activeTabPanel} index={FilterDashboardTabPanels.TOURNAMENTS}>
                            <TabPanelHeading />
                            <List>
                                {tournaments.map(([id, label]) => (
                                    <StyledListItemButton
                                        key={id}
                                        onClick={() => toggleFilter({ id, label, type: FilterDashboardTabPanels.TOURNAMENTS })}
                                    >
                                        <ListItemText>{label}</ListItemText>
                                        <RightListItemText>{id}</RightListItemText>
                                    </StyledListItemButton>
                                ))}
                            </List>
                        </TabPanel>
                        <TabPanel value={activeTabPanel} index={FilterDashboardTabPanels.EVENTS}>
                            <TabPanelHeading />
                            <List>
                                {events.map(([id, label]) => (
                                    <StyledListItemButton
                                        key={id}
                                        onClick={() => toggleFilter({ id, label, type: FilterDashboardTabPanels.EVENTS })}
                                    >
                                        <ListItemText>{label}</ListItemText>
                                        <RightListItemText>{id}</RightListItemText>
                                    </StyledListItemButton>
                                ))}
                            </List>
                        </TabPanel>
                        <TabPanel value={activeTabPanel} index={FilterDashboardTabPanels.ONLY_ALERTS}>
                            <Typography color={'GrayText'} textAlign={'center'}>
                                Only display tournaments with alerts
                            </Typography>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="onlyAlerts"
                                            checked={filters.some((filter) => filter.type === FilterDashboardTabPanels.ONLY_ALERTS)}
                                            onChange={() => toggleFilter({ type: FilterDashboardTabPanels.ONLY_ALERTS })}
                                        />
                                    }
                                    label="Only Alerts"
                                />
                            </FormGroup>
                        </TabPanel>
                        <TabPanel value={activeTabPanel} index={FilterDashboardTabPanels.EXPAND_HEALTHY_TOURNAMENTS}>
                            <Typography color={'GrayText'} textAlign={'center'}>
                                Expand healthy tournaments by displaying all of their events
                            </Typography>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="expandHealthyTournaments"
                                            checked={filters.some(
                                                (filter) => filter.type === FilterDashboardTabPanels.EXPAND_HEALTHY_TOURNAMENTS
                                            )}
                                            onChange={() => toggleFilter({ type: FilterDashboardTabPanels.EXPAND_HEALTHY_TOURNAMENTS })}
                                        />
                                    }
                                    label="Expand healthy tournaments"
                                />
                            </FormGroup>
                        </TabPanel>
                    </Grid>
                </Grid>
            </Content>
        </StyledDialog>
    );
};

const ActionableFilters: React.FC<{
    filters: FilterDashboardSearchParams[];
    toggleFilter: (searchParam: FilterDashboardSearchParams) => void;
}> = ({ filters, toggleFilter }) => {
    const searchableFilters = filters.filter((filter): filter is SearchableSearchParams => SEARCHABLE_FILTERS.includes(filter.type));

    return (
        <ActionableFiltersSection>
            {searchableFilters.map(({ id, label, type }) => (
                <Chip
                    className="actionable-filters__chip"
                    key={id + label}
                    label={label}
                    onDelete={() => toggleFilter({ type, id, label })}
                />
            ))}
        </ActionableFiltersSection>
    );
};

const TabPanel: React.FC<{
    children?: React.ReactNode;
    index: number;
    value: FilterDashboardTabPanels;
}> = ({ children, value, index }) => {
    return (
        <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`}>
            {value === index && <Box sx={{ paddingY: 3 }}>{children}</Box>}
        </div>
    );
};

const TabPanelHeading: React.FC = () => {
    return (
        <ListItemHeading>
            <ListItemText>
                <Typography fontSize={'0.9rem'} color={'GrayText'}>
                    Name
                </Typography>
            </ListItemText>
            <RightListItemText>
                <Typography fontSize={'0.9rem'} color={'GrayText'}>
                    ID
                </Typography>
            </RightListItemText>
        </ListItemHeading>
    );
};

const ActionSection = styled(Box)({
    backgroundColor: 'var(--light)',
    borderTop: '1px solid #dee',
    borderBottom: '1px solid #dee',
    display: 'flex',
    justifyContent: 'space-between'
});

const ActionableFiltersSection = styled(Box)({
    maxWidth: 720,
    flexWrap: 'wrap',
    '.actionable-filters__chip': {
        margin: '0 10px 10px 0'
    }
});

const Content = styled(DialogContent)({
    paddingLeft: 0,
    paddingTop: 16,
    paddingBottom: 0
});

const LeftGrid = styled(Grid)({
    backgroundColor: 'var(--light)',
    borderRight: '1px solid #dee'
});

const ListItemHeading = styled(ListItem)({
    display: 'flex',
    justifyContent: 'space-between'
});

const StyledDialog = styled(Dialog)({
    '.MuiDialog-paper': {
        maxHeight: '60%'
    }
});

const StyledListItemButton = styled(ListItemButton)({
    display: 'flex',
    justifyContent: 'space-between'
});

const RightListItemText = styled(ListItemText)({
    textAlign: 'right'
});
