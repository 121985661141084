import { AlertTypes, Alerts } from '../enums';
import { LogError } from '../types';

const FIVE_MINUTES = 300_000;

interface EventIssueQuantity {
    count: number;
    lastErrorDateTime: number;
    eventIssues: LogError[];
}

type HttpError = LogError & {
    status: number;
};

class ErrorClassifier {
    public getAlertTypeFromEventIssues(eventIssues: LogError[]): AlertTypes {
        return Array.from(this.groupEventIssueQuantities(eventIssues)).reduce((alertType, [currentAlert, { eventIssues }]) => {
            const nextAlertType = this.getAlertType(currentAlert, eventIssues);

            if (nextAlertType === AlertTypes.RED) {
                alertType = nextAlertType;
            } else if (nextAlertType === AlertTypes.YELLOW && alertType !== AlertTypes.RED) {
                alertType = nextAlertType;
            }

            return alertType;
        }, AlertTypes.GREEN as AlertTypes);
    }

    private getAlertType(alert: Alerts, eventIssues: LogError[]): AlertTypes {
        const lastError = eventIssues[eventIssues.length - 1];
        const latestTimestamp = lastError.timestamps[lastError.timestamps.length - 1];

        // If error happened more than 5 minutes ago
        if (Date.now() - latestTimestamp > FIVE_MINUTES) {
            return AlertTypes.YELLOW;
        }

        switch (alert) {
            case Alerts.AudioTracksLength:
            case Alerts.AudioSegmentPlaylistDuration:
            case Alerts.CurrentDvrWindowLength:
            case Alerts.DiscontinuityMisalignment:
            case Alerts.FragDecoded:
            case Alerts.HasCaptions:
            case Alerts.HttpSegmentStatusCode:
            case Alerts.InvalidContainerFormat:
            case Alerts.LinearChannelNotLive:
            case Alerts.NegativeSegmentDuration:
            case Alerts.PlaylistNotUpdating:
            case Alerts.SegmentBitrate:
            case Alerts.SegmentDimensionsIsValid:
            case Alerts.SegmentDuration:
            case Alerts.SegmentFrameRate:
            case Alerts.SegmentKeyFrameAligned:
            case Alerts.SegmentKeyFrameInterval:
            case Alerts.SegmentsNotFound:
            case Alerts.TargetDuration:
            case Alerts.UnableToFetchMasterPlaylist:
            case Alerts.UnableToFetchMediaPlaylists:
            case Alerts.VariantsLength:
            case Alerts.VideoSegmentPlaylistDuration: {
                return AlertTypes.RED;
            }
            case Alerts.HttpStatusCode:
            case Alerts.HttpStatusCodeDGE:
            case Alerts.HttpStatusCodeDGENoResponse:
            case Alerts.HttpStatusCodeNoResponse: {
                const gatewayTimeoutErrors = eventIssues.filter((issue) => (issue as HttpError).status === 504);

                if (gatewayTimeoutErrors.length) {
                    return this.gatewayTimeoutAlertType(gatewayTimeoutErrors);
                }

                return AlertTypes.RED;
            }
            default:
                return AlertTypes.GREEN;
        }
    }

    /**
     * Builds a tolerance for 504s. More than 9 in a 5min period
     */
    private gatewayTimeoutAlertType(gatewayTimeoutErrors: LogError[]): AlertTypes {
        if (gatewayTimeoutErrors.length < 9) {
            return AlertTypes.YELLOW;
        }
        const firstError = gatewayTimeoutErrors[0];
        const lastErrors = gatewayTimeoutErrors[gatewayTimeoutErrors.length - 1];

        const firstErrorTime = firstError.timestamps[0];
        const lastErrorTime = lastErrors.timestamps[lastErrors.timestamps.length - 1];

        return lastErrorTime - firstErrorTime <= FIVE_MINUTES ? AlertTypes.RED : AlertTypes.YELLOW;
    }

    /**
     * Finds how many times an EventIssue occurred
     */
    private groupEventIssueQuantities(eventIssues: LogError[]): Map<Alerts, EventIssueQuantity> {
        return eventIssues.reduce((eventIssues, eventIssue) => {
            const { errorCode, timestamps } = eventIssue;
            const eventIssueQuantity = eventIssues.get(errorCode);

            if (eventIssueQuantity) {
                eventIssues.set(errorCode, {
                    count: eventIssueQuantity.count + 1,
                    lastErrorDateTime: timestamps[timestamps.length - 1],
                    eventIssues: eventIssueQuantity.eventIssues.concat(eventIssue)
                });
            } else {
                eventIssues.set(errorCode, {
                    count: 0,
                    lastErrorDateTime: timestamps[timestamps.length - 1],
                    eventIssues: [eventIssue]
                });
            }

            return eventIssues;
        }, new Map<Alerts, EventIssueQuantity>());
    }
}

export const errorClassifier = new ErrorClassifier();
