import styled from '@emotion/styled';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { css } from '../utilities';
import { Edit } from './icons';

export interface TournamentHeadingProps {
    count?: string | number;
    href?: string;
    name: string;
    onEditClick?: () => void;
}

const StyledHeading = styled('div')({
    backgroundColor: 'var(--status-green)',
    margin: 0,
    minHeight: 70,
    color: 'var(--font-color)',
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '100%',
    userSelect: 'none'
});

const StyledCount = styled('div')({
    fontSize: 36,
    padding: '6px 12px',
    borderLeft: '1px solid var(--light)',
    minWidth: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const StyledName = styled('div')({
    fontSize: 20,
    padding: '6px 12px',
    display: 'flex',
    alignSelf: 'center'
});

const StyledEdit = styled('button')({
    border: 'none',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    cursor: 'pointer',
    fill: 'var(--font-color)',
    transition: 'transform 250ms ease',
    '&:hover, &:focus': {
        transform: 'scale(1.3)'
    }
});

const linkStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: 'var(--font-color)',
    textDecoration: 'none',
    '&:hover, &:focus': {
        '.tournament__heading': {
            backgroundColor: 'var(--dark-green)',
            transform: 'scale(1.02)'
        }
    }
};

const StyledNativeLink = styled('a')(linkStyles);
const StyledLink = styled(Link)(linkStyles);

const StyledTournamentInfo = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
});

const Wrapper: React.FC<{ href?: string; children: React.ReactNode }> = ({ children, href }) => {
    if (!href) {
        return <StyledTournamentInfo>{children}</StyledTournamentInfo>;
    }

    return href.includes('http') ? (
        <StyledNativeLink className="tournament__link" href={href} target="_blank" rel="noreferrer">
            {children}
        </StyledNativeLink>
    ) : (
        <StyledLink className="tournament__link" to={href}>
            {children}
        </StyledLink>
    );
};

export const TournamentHeading: React.FC<TournamentHeadingProps> = ({ name, count, href, onEditClick }: TournamentHeadingProps) => {
    return (
        <StyledHeading className={css('tournament__heading', href && 'tournament__heading-link')}>
            <StyledEdit title="Edit validation configuration" onClick={onEditClick}>
                <Edit />
            </StyledEdit>
            <Wrapper href={href}>
                <StyledName className="tournament__name">
                    <span>{name}</span>
                </StyledName>
                {count !== undefined && (
                    <StyledCount className="tournament__count">
                        <span>{count}</span>
                    </StyledCount>
                )}
            </Wrapper>
        </StyledHeading>
    );
};
