import { AlertTypes } from '../../enums';
import { noop } from '../../utilities';
import { AppContextType } from './types';

export const INITIAL_STATE: AppContextType = {
    platformStatus: AlertTypes.GREEN,
    isMonitoringApiDown: false,
    isLegendActive: false,
    setIsLegendActive: noop,
    dgeEvents: [],
    monitoringState: undefined,
    lastUpdateTime: undefined,
    tournamentCards: [],
    tournamentLinks: []
};
